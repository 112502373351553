<template>
  <div class="mt-20">
    <h5 class="font-weight-bold text-dark pb-2">
      Misafirlerinizle buluşacağınız bir buluşma noktası ekleyin
    </h5>
    <div class="form-group d-flex flex-row align-items-center justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" v-if="points.length < 5" @click="$bvModal.show('modal-add-meeting-point')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="points.length == 0">Bir buluşma noktası ekle</span>
        <span v-else>Başka bir buluşma noktası ekle</span>
      </button>
      <label class="font-weight-bold text-muted pt-3" v-else><em>Daha fazla buluşma noktası ekleyemezsiniz</em></label>
    </div>
    
    <div>
      <div
        class="form-group"
        v-for="(point, index) in points"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-control mr-4" style="width: auto;">
            <div class="symbol">
              <span class="symbol-label">{{ index + 1 }}</span>
            </div>
          </span>
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title pr-4 flex-fill">
                {{ point.title.tr }}
              </div>
              <div class="option-focus d-flex flex-fill justify-content-end">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditPointModal(point.id)"
                >
                  <i class="flaticon2-edit"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removePoint(index)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 pt-0">
              <span>{{ point.description.tr }}</span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <gmap-map
      v-if="points.length > 0"
      ref="map"
      :options="map.options"
      :center="map.center"
      :zoom="map.zoom"
      style="width:100%;  height: 400px;"
    >
      <gmap-marker
        v-for="(point, index) in points"
        :key="`marker_${index}`"
        :position="point.position"
        :clickable="false"
        :draggable="false"
        :label="String(index + 1)"
        @click="map.center=point.position"
      />
    </gmap-map>

    <!--Begin: Add new meeting point modal-->
    <b-modal id="modal-add-meeting-point" ref="modal-add-meeting-point" :lazy="false" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/{{ addItemModal.steps.length }}</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>

      <div class="max-h-355px">
        <div v-show="addItemModal.activeStep === 1">
          <div class="form-group" v-if="addItemModal.isAutoCompleteAvailable">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Bir konum girin"
                @place_changed="setPlace($event, addItemModal)">
              </gmap-autocomplete>
            </div>
          </div>
          <label class="text-body pb-2" v-else>Haritada istediğiniz konumu bulamadınız mı? <a @click="addItemModal.isAutoCompleteAvailable = true" href="javascript:;" class="font-weight-bold">Bir konum arayın.</a></label>
          <div class="form-group" style="border: 2px solid var(--secondary);">  
            <gmap-map
              ref="addModalMap"
              :options="map.options"
              :center="addItemModal.mapCenter"
              :zoom="map.zoom"
              @click="meetingPointSelected($event, addItemModal)"
              style="width:100%; height: 300px;"
            >
              <gmap-marker
                v-if="addItemModal.point.position != null"
                :position="addItemModal.point.position"
                :clickable="false"
                :draggable="true"
                @dragend="meetingPointSelected($event, addItemModal)"
              />
            </gmap-map>
          </div>
        </div>
        <div v-show="addItemModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlık girin</label>
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Türkçe başlık"
              v-model="addItemModal.point.title.tr"
            />
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Misafirlerinizin sizi nasıl bulacağını kısaca açıklayın</label>
            <textarea
              class="form-control"
              placeholder="Türkçe açıklayın"
              rows="3"
              v-model="addItemModal.point.description.tr"
            >
            </textarea>
          </div>
        </div>
        <div v-show="addItemModal.activeStep === 3">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlık girin</label>
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="İngilizce başlık"
              v-model="addItemModal.point.title.en"
            />
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Misafirlerinizin sizi nasıl bulacağını kısaca açıklayın</label>
            <textarea
              class="form-control"
              placeholder="İngilizce açıklayın"
              rows="3"
              v-model="addItemModal.point.description.en"
            >
            </textarea>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.point.title.en == '' || addItemModal.point.description.en == ''"
            v-if="addItemModal.activeStep == addItemModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isModalNextDisabled(addItemModal)"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new hotel pick up region modal-->

    <!--Begin: Edit meeting point modal-->
    <b-modal id="modal-edit-meeting-point" ref="modal-edit-meeting-point" :lazy="false" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.activeStep }} ADIM/{{ editItemModal.steps.length }}</label>
            <h5>{{ editItemModal.steps[editItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>

      <div class="max-h-355px">
        <div v-show="editItemModal.activeStep === 1">
          <div class="form-group" v-if="editItemModal.isAutoCompleteAvailable">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Buluşma noktası girin"
                @place_changed="setPlace($event, editItemModal)">
              </gmap-autocomplete>
            </div>
          </div>
          <label class="text-body pb-2" v-else>Haritada istediğiniz konumu bulamadınız mı? <a @click="editItemModal.isAutoCompleteAvailable = true" href="javascript:;" class="font-weight-bold">Bir konum arayın.</a></label>
          <div class="form-group" style="border: 2px solid var(--secondary);">
            <gmap-map
              ref="editModalMap"
              :options="map.options"
              :center="editItemModal.mapCenter"
              :zoom="map.zoom"
              @click="meetingPointSelected($event, editItemModal)"
              style="width:100%; height: 300px;"
            >
              <gmap-marker
                v-if="editItemModal.point.position != null"
                :position="editItemModal.point.position"
                :clickable="false"
                :draggable="true"
                @dragend="meetingPointSelected($event, editItemModal)"
              />
            </gmap-map>
          </div>
        </div>
        <div v-show="editItemModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlık girin</label>
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Türkçe başlık"
              v-model="editItemModal.point.title.tr"
            />
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Misafirlerinizin sizi nasıl bulacağını kısaca açıklayın</label>
            <textarea
              class="form-control"
              placeholder="Türkçe açıklayın"
              rows="3"
              v-model="editItemModal.point.description.tr"
            >
            </textarea>
          </div>
        </div>
        <div v-show="editItemModal.activeStep === 3">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlık girin</label>
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="İngilizce başlık"
              v-model="editItemModal.point.title.en"
            />
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Misafirlerinizin sizi nasıl bulacağını kısaca açıklayın</label>
            <textarea
              class="form-control"
              placeholder="İngilizce açıklayın"
              rows="3"
              v-model="editItemModal.point.description.en"
            >
            </textarea>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editItemModal.activeStep = editItemModal.activeStep - 1" 
            v-if="editItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editItemModal.point.title.en == '' || editItemModal.point.description.en == ''"
            v-if="editItemModal.activeStep == editItemModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isModalNextDisabled(editItemModal)"
            @click="editItemModal.activeStep = editItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit meeting point modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';
export default {
  name: 'ExperienceWizardMeetingPointPickUp',
  props: ['experienceId'],
  data() {
    return {
      map: {
        center: { lat: 41.0055066, lng: 39.731024 },
        zoom: 16,
        options:{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false
        }
      },
      addItemModal: {
        mapCenter: { lat: 41.0055066, lng: 39.731024 },
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Haritadan buluşma noktası seçin'
          },
          {
            number: 2,
            title: 'Buluşma noktasını Türkçe açıklayın'
          },
          {
            number: 3,
            title: 'Buluşma noktasını İngilizce açıklayın'
          }
        ],
        isAutoCompleteAvailable: false,
        point: {
          position: null,
          title: {
            tr: '',
            en: ''
          },
          description: {
            tr: '',
            en: ''
          }
        }
      },
      editItemModal: {
        mapCenter: { lat: 41.0055066, lng: 39.731024 },
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Haritadan buluşma noktası seçin'
          },
          {
            number: 2,
            title: 'Buluşma noktasını Türkçe açıklayın'
          },
          {
            number: 3,
            title: 'Buluşma noktasını İngilizce açıklayın'
          }
        ],
        isAutoCompleteAvailable: false,
        point: {
          id: null,
          position: null,
          title: {
            tr: '',
            en: ''
          },
          description: {
            tr: '',
            en: ''
          }
        }
      },
      points: []
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi
  },
  watch: {
    'points.length': function(val) {
      if (val > 0) {
        this.updateBounds();
      }
    }
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    getPoints() {
      ApiService.get(`experience/meeting/meeting-point-pickup/points/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.points = data.points;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    updateBounds() {
      this.$refs.map.$mapPromise.then((map) => {
        var bounds = new this.google.maps.LatLngBounds();
        this.points.forEach((point) => {
          bounds.extend(point.position);
        });
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },

    isModalNextDisabled(modal) {
      if (modal.activeStep === 1) {
        return modal.point.position === null;
      } else if (modal.activeStep === 2) {
        return modal.point.title.tr == '' || modal.point.description.tr == '';
      } else {
        return true;
      }
    },

    meetingPointSelected(event, modal) {
      modal.point.position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    },
    setPlace(place, modal) {
      modal.point.position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      modal.mapCenter = modal.point.position;
    },

    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1
      this.addItemModal.isAutoCompleteAvailable = false;
      this.addItemModal.point.position = null;
      this.addItemModal.point.title = { tr: '', en: '' };
      this.addItemModal.point.description = { tr: '', en: '' };
    },
   
    handleAddItemModalOk() {
      const point = Object.assign({}, this.addItemModal.point);
      //const point = this.addItemModal.point;
      const data = {
        pid: this.experienceId,
        lat: point.position.lat,
        lng: point.position.lng,
        title: JSON.stringify(point.title),
        description: JSON.stringify(point.description)
      }

      ApiService.post('experience/meeting/meeting-point-pickup/points/add.req.php', data)
      .then(({ data }) => {
        const newPoint = {
          id: data.pointID,
          position: point.position,
          title: point.title,
          description: point.description,
        }
        this.points.push(newPoint);
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    removePoint(index) {
      const pointID = this.points[index].id;
      const data = {
        pointID,
        pid: this.experienceId
      }

      ApiService.post('experience/meeting/meeting-point-pickup/points/remove.req.php', data)
      .then(() => {
        this.points.splice(index, 1);
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    openEditPointModal(id) {
      const point = this.points.filter(function(el) {
        return el.id == id;
      })[0];
      this.map.center = point.position;
      this.editItemModal.point.id = id;
      this.editItemModal.point.position = point.position;
      this.editItemModal.point.title = point.title;
      this.editItemModal.point.description = point.description;
      this.$bvModal.show('modal-edit-meeting-point');
    },

    handleEditItemModalCancel() {
      this.editItemModal.activeStep = 1;
      this.editItemModal.isAutoCompleteAvailable = false;
      this.editItemModal.point.id = null;
      this.editItemModal.point.position = null;
      this.editItemModal.point.title = { tr: '', en: '' };
      this.editItemModal.point.description = { tr: '', en: '' };
    },

    handleEditItemModalOk() {
      const point = this.editItemModal.point;
      const self = this;
      const index = this.points.indexOf(
        self.points.filter(function(el) {
        return el.id == point.id;
      })[0]);

      const data = {
        pointID: point.id,
        lat: point.position.lat,
        lng: point.position.lng,
        title: JSON.stringify(point.title),
        description: JSON.stringify(point.description),
        pid: this.experienceId,
      }

      ApiService.post('experience/meeting/meeting-point-pickup/points/set.req.php', data)
      .then(() => {
        const newPoint = {
          id: point.id,
          position: point.position,
          title: point.title,
          description: point.description,
        }
        this.points[index] = newPoint;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    }

  }
}
</script>

<style>

</style>