<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Etiketi düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">Deneyiminizin etiketini değiştirin.</p>

    <div
      class="alert alert-warning bg-light-warning my-8"
      role="alert"
      v-if="error.status"
    >
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="row">
      <div
        class="form-group col-md-4"
        v-for="(item, index) in ribbons"
        :key="index"
      >
        <label
          class="option selectable option-status mr-4"
          :class="{ selected: selectedRibbon == item.id }"
        >
          <span class="option-control d-none">
            <span class="radio radio-dark">
              <input
                type="radio"
                name="status_option"
                :value="item.id"
                v-model="selectedRibbon"
              />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head justify-content-center">
              <span class="option-title">
                <span
                  class="label label-pill label-inline label-lg text-white font-size-lg text-uppercase font-weight-bold"
                  :style="`background: ${item.color}`"
                  >{{ item.title.tr }}</span
                >
              </span>
            </span>
          </span>
        </label>
      </div>
      <div class="form-group col-md-4">
        <label
          class="option selectable option-status mr-4"
          :class="{ selected: selectedRibbon == 0 }"
        >
          <span class="option-control d-none">
            <span class="radio radio-dark">
              <input
                type="radio"
                name="status_option"
                value="0"
                v-model="selectedRibbon"
              />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head justify-content-center">
              <span class="option-title"> Etiket yok </span>
            </span>
          </span>
        </label>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center"
          :disabled="isSaving"
          @click="setRibbon"
        >
          <div class="spinner spinner-white pr-10" v-if="isSaving"></div>
          <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ['experienceId'],
  data() {
    return {
      selectedRibbon: null,
      ribbons: [],
      isSaving: false,
      error: {
        status: false,
        message: '',
      },
    }
  },
  mounted() {
    this.getRibbonList();
  },
  methods: {
    getRibbonList() {
      ApiService.get('ribbon/listings.req.php')
      .then(({ data }) => {
        this.ribbons = data.ribbons;
        this.error.status = false;
        this.getRibbon()
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    getRibbon() {
      ApiService.get(`experience/ribbon/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedRibbon = data.ribbon;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    setRibbon() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        ribbon: this.selectedRibbon
      }
      ApiService.post('experience/ribbon/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
  }
}
</script>

<style>
</style>