<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Listelenme puanını düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizin puanını artırarak daha yukarılarda listelenmesini sağlayabilirsiniz.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="form-group mb-10">
          <div class="input-group input-group-lg input-group-solid">
            <input
              placeholder="Puan girin"
              type="number"
              class="form-control"
              v-model.number="highlightPoint"
              @input="handleInputChange"
            />
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="d-flex flex-row">
          <div class="card-body py-0">
          <div class="d-flex flex-row align-items-start">
            <span class="svg-icon svg-icon-2x svg-icon-gray mr-2" :class="[{'svg-icon-success': newOrder < currentOrder}, {'svg-icon-primary': newOrder > currentOrder}]">
              <inline-svg src="media/svg/icons/Navigation/Angle-double-up.svg" v-if="newOrder < currentOrder"/>
              <inline-svg src="media/svg/icons/Navigation/Angle-double-down.svg" v-if="newOrder > currentOrder"/>
              <inline-svg src="media/svg/icons/Navigation/Angle-double-right.svg" v-if="newOrder == currentOrder"/>
            </span>
            <div>
              <span class="card-title font-weight-bolder text-dark-75 font-size-h3 mb-0 d-block">{{ newOrder }}. Sıra</span>
              <span class="font-weight-bold text-muted font-size-sm">Listelenme sırası</span>
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="d-flex flex-row align-items-start">
            <span class="svg-icon svg-icon-2x svg-icon-gray mr-2" :class="[{'svg-icon-success': newTotalPoint > currentTotalPoint}, {'svg-icon-primary': newTotalPoint < currentTotalPoint}]">
              <inline-svg src="media/svg/icons/Navigation/Angle-double-up.svg" v-if="newTotalPoint > currentTotalPoint"/>
              <inline-svg src="media/svg/icons/Navigation/Angle-double-down.svg" v-if="newTotalPoint < currentTotalPoint"/>
              <inline-svg src="media/svg/icons/Navigation/Angle-double-right.svg" v-if="newTotalPoint == currentTotalPoint"/>
            </span>
            <div>
              <span class="card-title font-weight-bolder text-dark-75 font-size-h3 mb-0 d-block">{{ newTotalPoint }}</span>
              <span class="font-weight-bold text-muted font-size-sm">Toplam puan</span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div v-if="sharedOrder.status">
      <div class="text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-normal">Bu puana sahip başka bir deneyim bulunmakta.</span>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setHighlightPoint"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data() {
    return {
      highlightPoint: 0,
      rating: 0,
      currentOrder: 0,
      newOrder: 0,
      currentTotalPoint: 0,
      newTotalPoint: 0,
      sharedOrder: {
        status: false,
        experience: []
      },
      isSaving: false,
      error: {
        status: false,
        message: '',
      },
      timeout: null
    }
  },
  mounted() {
   this.getHighlightPoint();
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.highlightPoint == '') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getHighlightPoint() {
      ApiService.get(`experience/order/getHighlightPoint.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.highlightPoint = data.point;
        this.rating = data.rating;

        this.currentOrder = data.currentOrder;
        this.newOrder = data.currentOrder;
        this.sharedOrder.status = data.sharedOrder == 1;
        
        this.currentTotalPoint = data.point + data.rating;
        this.newTotalPoint = data.point + data.rating;

        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    setHighlightPoint() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        point: this.highlightPoint
      }
      ApiService.post('experience/order/setHighlightPoint.req.php', data)
      .then(() => {
        this.isSaving = true;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = true;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    getNewOrder() {
      let point = this.highlightPoint + this.rating;   
      ApiService.get(`experience/order/getNewOrder.req.php?pid=${this.experienceId}&point=${point}`)
      .then(({ data }) => {
        this.newOrder = data.newOrder;
        this.sharedOrder.status = data.sharedOrder.status == 1;
        this.sharedOrder.experience = data.sharedOrder.experience;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    handleInputChange() {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.newTotalPoint = self.rating + self.highlightPoint;
        self.getNewOrder();
      }, 1000);
    },
  }
}
</script>

<style scoped>
.form-group input[type='number'] {
  height: 50px;
}
</style>