<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Öne çıkanları düzenle
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizi öne çıkaran, onu en iyi ifade eden bilgileri birer cümle ile anlatın.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group d-flex flex-row justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" @click="$bvModal.show('modal-add-highlight-item')" v-if="highlights.length < 5">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="highlights.length == 0">Bir öğe ekle</span>
        <span v-else>Başka bir öğe ekle</span>
      </button>
      <label class="font-weight-bold text-muted pt-3" v-else><em>Daha fazla öğe ekleyemezsiniz</em></label>
      <b-form-radio-group
        :options="languageOptions"
        v-model="selectedLanguage"
        buttons
        button-variant="outline-secondary"
        name="radios-btn-language"
        v-if="highlights.length > 0"
      ></b-form-radio-group>
    </div>

    <div>
      <div
        class="form-group"
        v-for="(highlight, index) in highlights"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title pr-4 flex-fill">
                {{ highlight[selectedLanguage] }}
              </div>
              <div class="option-focus d-flex flex-fill justify-content-end">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditHighlightModal(index)"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeHighlight(index)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setHighlight"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

    <!--Begin: Add item modal-->
    <b-modal id="modal-add-highlight-item" ref="modal-add-highlight-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Öne çıkan bilgi ekle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="3"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxHighlightLength"
          ref="addHighlightModalTR"
          v-model="addItemModal.highlight.tr"
        ></textarea>
        <span class="text-muted pl-1">
          {{ addItemModal.highlight.tr.length }} / {{ maxHighlightLength }}
        </span>
      </div>
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="3"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxHighlightLength"
          ref="addHighlightModalEN"
          v-model="addItemModal.highlight.en"
        ></textarea>
        <span class="text-muted pl-1">
        {{ addItemModal.highlight.en.length }} / {{ maxHighlightLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModalIsDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->

    <!--Begin: Edit item modal-->
    <b-modal id="modal-edit-highlight-item" ref="modal-edit-highlight-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Öne çıkan bilgiyi düzenle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="3"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxHighlightLength"
          ref="editHighlightModalTR"
          v-model="editItemModal.highlight.tr"
        ></textarea>
        <span class="text-muted pl-1">
          {{ editItemModal.highlight.tr.length }} / {{ maxHighlightLength }}
        </span>
      </div>
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="3"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxHighlightLength"
          ref="editHighlightModalEN"
          v-model="editItemModal.highlight.en"
        ></textarea>
        <span class="text-muted pl-1">
        {{ editItemModal.highlight.en.length }} / {{ maxHighlightLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editItemModalIsDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorHighlight',
  props: ['experienceId'],
  data() {
    return {
      highlights: [],
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      addItemModal: {
        highlight: {
          tr: '',
          en: ''
        }
      },
      editItemModal: {
        indexOfSelectedHighlight: null,
        highlight: {
          tr: '',
          en: ''
        }
      },
      maxHighlightLength: 150,
      error: {
        status: false,
        message: 'Devam edebilmek için en az 3 adet öne çıkan bilgi eklemelisiniz.',
      },
      isSaving: false
    }
  },
  computed: {
    addItemModalIsDisabled() {
      if (
        this.addItemModal.highlight.tr.length === 0 ||
        this.addItemModal.highlight.en.length === 0) {
        return true;
      }
      return false;
    },
    
    editItemModalIsDisabled() {
      if (
        this.editItemModal.highlight.tr.length === 0 ||
        this.editItemModal.highlight.en.length === 0) {
        return true;
      }
      return false;
    },

    isSaveButtonDisabled() {
      if (this.highlights.length < 3) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getHighlights();
  },
  methods: {
    getHighlights() {
      ApiService.get(`experience/highlight/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.highlights = data.highlights;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setHighlight() {
      const data = {
        id: this.experienceId,
        highlights: JSON.stringify(this.highlights)
      }

      this.isSaving = true;
      ApiService.post('experience/highlight/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },


    handleAddItemModalCancel() {
      this.addItemModal.highlight.tr = '';
      this.addItemModal.highlight.en = '';
    },
    handleEditItemModalCancel() {
      this.editItemModal.indexOfSelectedHighlight = null;
      this.editItemModal.highlight.tr = '';
      this.editItemModal.highlight.en = '';
    },
    handleAddItemModalOk() {
      const newHighlight = {
        tr: this.$refs.addHighlightModalTR.value,
        en: this.$refs.addHighlightModalEN.value
      };
      this.highlights.push(newHighlight);
    },

    openEditHighlightModal(index) {
      const highlight = this.highlights[index];

      this.editItemModal.indexOfSelectedHighlight = index;
      this.editItemModal.highlight.tr = highlight.tr;
      this.editItemModal.highlight.en = highlight.en;
      this.$bvModal.show('modal-edit-highlight-item');
    },

    handleEditItemModalOk() {
      const index = this.editItemModal.indexOfSelectedHighlight;
      const highlight = {
        tr: this.$refs.editHighlightModalTR.value,
        en: this.$refs.editHighlightModalEN.value
      };
      this.highlights[index] = highlight;
    },

    removeHighlight(index) {
      this.highlights.splice(index, 1);
    }
  }
}
</script>

<style>

</style>