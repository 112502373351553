<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">URL - SEO Uyumlu</h4>
      <button
        class="btn btn-outline-dark font-weight-bold"
        @click="$emit('edit')"
      >
        Düzenle
      </button>
    </div>

    <div>
      <div class="form-group" v-if="url.tr.length > 0">
        <span class="text-body font-size-h6 font-weight-bolder"
          >Türkçe:
          <a
            :href="`https://www.zenofontours.com/${this.url.tr}-t${this.experienceId}`"
            target="_blank"
            rel="noopener noreferrer"
            class="text-body font-weight-normal"
            ><u>{{
              `https://www.zenofontours.com/${this.url.tr}-t${this.experienceId}`
            }}</u></a
          ></span
        >
      </div>
      <div class="form-group" v-if="url.en.length > 0">
        <span class="text-body font-size-h6 font-weight-bolder"
          >İngilizce:
          <a
            :href="`https://www.zenofontours.com/${this.url.en}-t${this.experienceId}`"
            target="_blank"
            rel="noopener noreferrer"
            class="text-body font-weight-normal"
            ><u>{{
              `https://www.zenofontours.com/${this.url.en}-t${this.experienceId}`
            }}</u></a
          ></span
        >
      </div>
      <div v-if="url.tr.length === 0 && url.en.length === 0">
        <p class="font-size-h6 font-weight-normal text-body">
          Arama motorlarına ve kullanıcılara etkinliği en iyi anlatan url
          bilgisini girin.
        </p>
      </div>
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: {
    experienceId: Number,
    url: Object
  }
}
</script>