<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Aktif satış kanalları</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="d-flex align-items-center flex-wrap mb-8">
        <div class="symbol symbol-50 symbol-light mr-5">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-2x" :class="{'svg-icon-success': data.B2CIn == 1}">
              <inline-svg src="media/svg/icons/Devices/LTE2.svg" v-if="data.B2CIn == 1"/>
              <inline-svg src="media/svg/icons/General/Lock.svg" v-else/>
            </span>
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <span class="font-weight-bold font-size-h6 mb-1" :class="[{'text-body': data.B2CIn == 1}, {'text-muted': data.B2CIn == 0}]">İç pazar</span>
          <span class="text-muted font-weight-normal font-size-lg">Yurt içi münferit müşteriler</span>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap mb-8">
        <div class="symbol symbol-50 symbol-light mr-5">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-2x" :class="{'svg-icon-success': data.B2COut == 1}">
              <inline-svg src="media/svg/icons/Devices/LTE2.svg" v-if="data.B2COut == 1"/>
              <inline-svg src="media/svg/icons/General/Lock.svg" v-else/>
            </span>
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <span class="font-weight-bold font-size-h6 mb-1" :class="[{'text-body': data.B2COut == 1}, {'text-muted': data.B2COut == 0}]">Dış pazar</span>
          <span class="text-muted font-weight-normal font-size-lg">Yurt dışı münferit müşteriler</span>
        </div>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style>
</style>