<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Neler sunuluyor?</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>
    
    <div>
      <div class="d-flex align-items-center flex-wrap mb-8" v-for="(item, index) in provides" :key="index">
        <div class="symbol symbol-50 symbol-light mr-5">
          <span class="symbol-label">
            <img :src="item.avatar" class="h-50 align-self-center" :alt="item.title[lang]">
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <span class="font-weight-bold text-body font-size-h6 mb-1">{{ item.title[lang] }}</span>
          <span class="text-muted font-weight-normal font-size-lg" v-if="item.description[lang] !== ''">{{ item.description[lang] }}</span>
        </div>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["provides", "lang"]
}
</script>

<style>

</style>