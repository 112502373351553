<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Sunacaklarınız hakkında ayrıntılar ekleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerinizi rahat ettirmek için hızlı onay, alınma servisi veya başka
      herhangi bir şey sunabilirsiniz.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group d-flex flex-row justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" @click="$bvModal.show('modal-add-item')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="selectedProvides.length == 0">Bir öğe ekle</span>
        <span v-else>Başka bir öğe ekle</span>
      </button>
      <b-form-radio-group
        :options="languageOptions"
        v-model="selectedLanguage"
        buttons
        button-variant="outline-secondary"
        name="radios-btn-language"
        v-if="selectedProvides.length > 0"
      ></b-form-radio-group>
    </div>

    <div class="d-flex justify-content-center min-h-100px" v-if="isSaving">
      <div class="spinner spinner-dark"></div>
    </div>
  
    <div v-else>
      <div
        class="form-group"
        v-for="(provide, index) in selectedProvides"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-control mr-4" style="width: auto;">
            <div class="symbol">
              <span class="symbol-label">
                <img
                  :src="provide.avatar"
                  style="width: 32px;"
                  :alt="provide.title[selectedLanguage]"
                />
              </span>
            </div>
          </span>
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                {{ provide.title[selectedLanguage] }}
              </div>
              <div class="option-focus">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditProvideModal(provide.id)"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeSelectedProvide(provide.id)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span
              class="option-body text-dark-50 pt-0"
              v-if="provide.description[selectedLanguage] !== ''"
            >
              {{ provide.description[selectedLanguage] }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <!--Begin: Add new item modal-->
    <b-modal id="modal-add-item" ref="modal-add-item" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/2</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addItemModal.activeStep === 1">
          <label class="text-muted pt-0 pb-2"><em>Toplam {{ provides.length }} adet</em></label>
          <div class="form-group" v-for="(item,index) in provides" :key="index">
            <div class="option selectable mb-3" :class="{selected: addItemModal.selectedItemId === item.id}" @click="addItemModal.selectedItemId = item.id">
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    {{ item.title.tr }}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="addProvideModalTR"
              v-model="addItemModal.selectedItemDescription.tr"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="addProvideModalEN"
              v-model="addItemModal.selectedItemDescription.en"
            ></textarea>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button type="button" class="btn btn-dark font-weight-bolder px-5 py-4" @click="ok" v-if="addItemModal.activeStep == addItemModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.selectedItemId == null"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new item modal-->


    <!--Begin: Edit item modal-->
    <b-modal id="modal-edit-item" ref="modal-edit-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.selectedItemTitle }}</label>
            <h5>Olanağınızı tanımlayın (isteğe bağlı)</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          ref="editProvideModalTR"
          v-model="editItemModal.selectedItemDescription.tr"
        ></textarea>
      </div>
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          ref="editProvideModalEN"
          v-model="editItemModal.selectedItemDescription.en"
        ></textarea>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button type="button" class="btn btn-dark font-weight-bolder px-5 py-4" @click="ok">
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorProvide',
  props: ['experienceId'],
  data() {
    return {
      provides: [],
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      selectedProvides: [],
      addItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Ne sunacağınızı seçin'
          },
          {
            number: 2,
            title: 'Olanağınızı tanımlayın (isteğe bağlı)'
          }
        ],
        selectedItemId: null,
        selectedItemDescription: {
          tr: '',
          en: ''
        }
      },
      editItemModal: {
        selectedItemId: null,
        selectedItemTitle: '',
        selectedItemDescription: {
          tr: '',
          en: ''
        }
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen en az bir adet hizmet ekleyin.',
      },
      isSaving: false
    };
  },
  mounted() {
    this.getProvides();
  },
  methods: {
    getProvides() {
      ApiService.get(`experience/provide/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.provides = data.provides;
        this.selectedProvides = data.experienceProvides;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1;
      this.addItemModal.selectedItemId = null;
      this.addItemModal.selectedItemDescription.tr = '';
      this.addItemModal.selectedItemDescription.en = '';
    },
    handleEditItemModalCancel() {
      this.editItemModal.selectedItemId = null;
      this.editItemModal.selectedItemDescription.tr = '';
      this.editItemModal.selectedItemDescription.en = '';
    },
    handleAddItemModalOk() {
      const provideID = this.addItemModal.selectedItemId;

      const data = {
        id: this.experienceId,
        provideID: provideID,
        provideDesc: JSON.stringify({
          tr: this.$refs.addProvideModalTR.value,
          en: this.$refs.addProvideModalEN.value
        })
      };
      
      this.isSaving = true;
      ApiService.post('experience/provide/add.req.php', data)
      .then(() => {
        this.getProvides();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
      
    },

    openEditProvideModal(id) {
      const provide = this.selectedProvides.filter(function(el) {
        return el.id == id;
      })[0];
      this.editItemModal.selectedItemId = id;
      this.editItemModal.selectedItemTitle = provide.title.tr;
      this.editItemModal.selectedItemDescription.tr = provide.description.tr;
      this.editItemModal.selectedItemDescription.en = provide.description.en;
      this.$bvModal.show('modal-edit-item');
    },

    handleEditItemModalOk() {
      const provideID = this.editItemModal.selectedItemId;

      const data = {
        id: this.experienceId,
        provideID: provideID,
        provideDesc: JSON.stringify({
          tr: this.$refs.editProvideModalTR.value,
          en: this.$refs.editProvideModalEN.value
        })
      };

      this.isSaving = true;
      ApiService.post('experience/provide/set.req.php', data)
      .then(() => {
        this.getProvides();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
      
    },

    removeSelectedProvide(provideID) {
      const data = {
        id: this.experienceId,
        provideID: provideID,
      };

      this.isSaving = true;
      ApiService.post('experience/provide/remove.req.php', data)
      .then(() => {
        this.getProvides();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    }
  },
};
</script>

<style scoped>
.option {
  cursor: initial;
}
.option.selectable {
  cursor: pointer;
}
</style>
