<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Listelenme</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

     <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Puan: <span class="font-weight-normal">{{ data.point }}</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Listelenme Sırası: <span class="font-weight-normal">{{ data.order }}. Sıra</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Toplam puan: <span class="font-weight-normal">{{ data.totalPoint }}</span></span>
      </div>  
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style>
</style>