<template>
  <div>
    <OverallBlocks :experience-id="experienceId"/>
    <div class="row">
      <div class="col-xl-6">
        <SalesChart :experience-id="experienceId"/>
      </div>
      <div class="col-xl-6">
        <SalesByCountry :experience-id="experienceId"/>
      </div>
    </div>
    <Bookings :experience-id="experienceId"/>
  </div>
</template>

<script>
import OverallBlocks from "@/view/pages/experience/components/overview/OverallBlocks";
import Bookings from "@/view/pages/experience/components/overview/Bookings";
import SalesChart from "@/view/pages/experience/components/overview/SalesChart";
import SalesByCountry from "@/view/pages/experience/components/overview/SalesByCountry";

export default {
  props: ["experienceId"],
  components: {
    OverallBlocks,
    SalesChart,
    Bookings,
    SalesByCountry
  }
}
</script>

<style>

</style>