<template>
  <OverlayModalBase @close="closeOverlayModal">
    <SalesChannels
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'salesChannels'"
    />
    <MaxParticipant
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'maxParticipant'"
    />
    <Participant
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'participant'"
    />
    <HighlightPoint
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'highlightPoint'"
    />
    <Status
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'status'"
    />
    <Category
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'category'"
    />
    <SeoURL
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'url'"
    />
    <Ribbon
      :experience-id="experienceId"
      @close="closeOverlayModal"
      v-if="productSettings.selectedSection == 'ribbon'"
    />
  </OverlayModalBase>
</template>

<script>
import OverlayModalBase from "@/view/pages/experience/base_ui/OverlayModalBase";
import SalesChannels from "@/view/pages/experience/components/editor/SalesChannels";
import MaxParticipant from "@/view/pages/experience/components/editor/MaxParticipant";
import Participant from "@/view/pages/experience/components/editor/Participant";
import HighlightPoint from "@/view/pages/experience/components/editor/HighlightPoint";
import Status from "@/view/pages/experience/components/editor/Status";
import Category from "@/view/pages/experience/components/editor/Category";
import SeoURL from "@/view/pages/experience/components/editor/SeoURL";
import Ribbon from "@/view/pages/experience/components/editor/Ribbon";

export default {
  props: ["experienceId", "productSettings"],
  components: {
    OverlayModalBase,
    SalesChannels,
    MaxParticipant,
    Participant,
    HighlightPoint,
    Status,
    Category,
    SeoURL,
    Ribbon
  },
  methods: {
    closeOverlayModal() {
      this.$emit('close');
    }
  }
}
</script>

<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>