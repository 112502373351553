<template>
  <VueDatePicker 
    v-model="date" 
    no-header 
    range 
    placeholder="Tarih aralığı" 
    color="#F64E60" 
    clearable 
    :format="format" 
    :min-date="minDate"
    :max-date="maxDate" 
    :locale="{lang: 'en'}"
     @onChange="onChange"
  >
    <template #activator="{ date }">
      <div class="input-icon w-100">
        <input type="text" placeholder="Tarih aralığı" class="form-control form-control-solid" :class="inputClass" :value="date" readonly/>
        <span>
          <i class="flaticon2-calendar-9 text-muted"></i>
        </span>
      </div>
    </template>
  </VueDatePicker>
</template>

<script>
const locale = {
  lang: { 
    name: 'tr',
    weekdays: 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split('_'),
    weekdaysShort: 'Paz_Pzt_Sal_Çar_Per_Cum_Cmt'.split('_'),
    weekStart: 1,
    months: 'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split('_'),
    monthsShort: 'Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara'.split('_'),
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    ordinal: n => `${n}º`,
    buttonCancel: 'Vazgeç',
    buttonValidate: 'Tamam'
  } 
};

import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

export default {
  props: ["format", "inputClass", "initialDate", "minDate", "maxDate"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      date: null,
      locale
    }
  },
  computed: {
    getInitialDate() {
      return this.initialDate;
    },
  },
  mounted() {
    this.date = this.getInitialDate;
  },
  methods: {
    onChange() {
      this.$emit('change', this.date);
    }
  }
}
</script>

<style scoped>
input {
  cursor: pointer;
}
</style>