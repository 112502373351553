<template>
  <div class="row">
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-info': !isError}" style="height: 100px;">
        <div class="card-body d-flex flex-column p-0" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x pt-6">
            <div class="text-dark-50 font-weight-bold font-size-h6">Gelir</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalIncomeText }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-success': !isError}" style="height: 100px;">
        <div class="card-body d-flex flex-column p-0" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x pt-6">
            <div class="text-dark-50 font-weight-bold font-size-h6">Rezervasyon</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalReservationText }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-warning': !isError}" style="height: 100px">
        <div class="card-body d-flex flex-column p-0" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x pt-6">
            <div class="text-dark-50 font-weight-bold font-size-h6">Toplam</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalPaxText }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="card card-custom bg-light gutter-b" :class="{'bg-light-danger': !isError}" style="height: 100px">
        <div class="card-body d-flex flex-column p-0" style="position: relative;">
          <div class="flex-grow-1 card-spacer-x pt-6">
            <div class="text-dark-50 font-weight-bold font-size-h6">İptal</div>
            <div class="font-weight-bolder font-size-h3 pt-2">{{ totalCancellationText }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data () {
    return {
      totalIncome: '',
      totalPax: 0,
      totalReservation: 0,
      totalCancellation: 0,
      isError: false
    }
  },
  computed: {
    totalIncomeText() {
      if (this.totalIncome == '') {
        return '-';
      } else {
        return this.totalIncome;
      }
    },
    totalPaxText() {
      if (this.totalPax > 0) {
        return this.totalPax + ' Pax';
      } else {
        return '-'
      }
    },
    totalReservationText() {
      if (this.totalReservation > 0) {
        return this.totalReservation + ' Adet';
      } else {
        return '-'
      }
    },
    totalCancellationText() {
      if (this.totalCancellation > 0) {
        return this.totalCancellation + ' Adet';
      } else {
        return '-'
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get(`experience/overview/overall/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.totalIncome = data.overall.totalIncome;
        this.totalReservation = data.overall.totalReservation;
        this.totalPax = data.overall.totalPax;
        this.totalCancellation = data.overall.totalCancellation;
        this.isError = false;
      })
      .catch(() => {
        this.isError = true;
      });
    }
  }
}
</script>

<style>

</style>