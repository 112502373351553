<template>
  <div>
    <ReviewItem v-for="(review, index) in reviews" :key="index" :review="review">
      <template v-slot:actions>
        <button class="btn btn-light-success font-weight-bolder btn-sm mr-3" @click="confirm(review.id)">
          <span class="svg-icon svg-icon-lg">
            <inline-svg
              src="media/svg/icons/Code/Done-circle.svg"
            />
          </span>
          Onayla
        </button>
        <button class="btn btn-light-danger font-weight-bolder btn-sm" @click="reject(review.id)">
          <span class="svg-icon svg-icon-lg">
            <inline-svg
              src="media/svg/icons/Code/Error-circle.svg"
            />
          </span>
          Reddet
        </button>
      </template>
    </ReviewItem>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import ReviewItem from "@/view/pages/experience/components/review/ReviewItem";
export default {
  props: ["experienceId"],
  components: {
    ReviewItem
  },
  data() {
    return {
      reviews: []
    }
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    getReviews() {
      ApiService.get(`experience/review/pending/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.reviews = data.reviews;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    confirm(reviewId) {
      const data = {
        reviewId,
        id: this.experienceId 
      }

      ApiService.post('experience/review/confirm.req.php', data)
      .then(() => {
        this.getReviews();
        this.$emit('confirmed');
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    reject(reviewId) {
      const data = {
        reviewId,
        status: 0,
        id: this.experienceId 
      }

      ApiService.post('experience/review/set.req.php', data)
      .then(() => {
        this.getReviews();
        this.$emit('rejected');
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    }

  }
}
</script>

<style>

</style>