<template>
  <OverlayModalBase @close="closeOverlayModal">
    <Title :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'title'"></Title>
    <Summary :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'summary'"></Summary>
    <Provide :experience-id="experienceId" v-if="productContent.selectedSection == 'provide'"></Provide>
    <Highlight :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'highlight'"></Highlight>
    <FullDescription :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'fullDesc'"></FullDescription>
    <Include :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'include'"></Include>
    <Exclude :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'exclude'"></Exclude>
    <Important :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'important'"></Important>
    <Photos :experience-id="experienceId" v-if="productContent.selectedSection == 'photos'"></Photos>
    <MaxParticipant :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'maxParticipant'"></MaxParticipant>
    <Duration :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'duration'"></Duration>
    <Participant :experience-id="experienceId" v-if="productContent.selectedSection == 'participant'"></Participant>
    <Price :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'price'"></Price>
    <Meeting :experience-id="experienceId" v-if="productContent.selectedSection == 'meeting'"></Meeting>
    <Itinerary :experience-id="experienceId" @close="closeOverlayModal" v-if="productContent.selectedSection == 'itinerary'"></Itinerary>
  </OverlayModalBase>
</template>

<script>
import OverlayModalBase from "@/view/pages/experience/base_ui/OverlayModalBase";
import Title from "@/view/pages/experience/components/editor/Title";
import Summary from "@/view/pages/experience/components/editor/Summary";
import Provide from "@/view/pages/experience/components/editor/Provide";
import Highlight from "@/view/pages/experience/components/editor/Highlight";
import FullDescription from "@/view/pages/experience/components/editor/FullDescription";
import Include from "@/view/pages/experience/components/editor/Include";
import Exclude from "@/view/pages/experience/components/editor/Exclude";
import Important from "@/view/pages/experience/components/editor/Important";
import Photos from "@/view/pages/experience/components/editor/Photos";
import MaxParticipant from "@/view/pages/experience/components/editor/MaxParticipant";
import Duration from "@/view/pages/experience/components/editor/Duration";
import Participant from "@/view/pages/experience/components/editor/Participant";
import Price from "@/view/pages/experience/components/editor/Price";
import Meeting from "@/view/pages/experience/components/editor/Meeting";
import Itinerary from "@/view/pages/experience/components/editor/Itinerary";

export default {
  props: ["experienceId", "productContent"],
  components: {
    OverlayModalBase,
    Title,
    Summary,
    Provide,
    Highlight,
    FullDescription,
    Include,
    Exclude,
    Important,
    Photos,
    MaxParticipant,
    Duration,
    Participant,
    Price,
    Meeting,
    Itinerary
  },
  methods: {
    closeOverlayModal() {
      this.$emit('close');
    }
  }
}
</script>

<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>