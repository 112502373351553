<template>
  <div class="mt-20">
    <h5 class="font-weight-bold text-dark pb-2">
      Misafirlerinizi hangi havalimanına bırakacaksınız?
    </h5>
    <div class="form-group d-flex flex-row align-items-center justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" v-if="airports.length === 0" @click="$bvModal.show('modal-add-airport-dropoff-region')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span>Bir havalimanı ekle</span>
      </button>
    </div>


    <div>
      <div class="form-group" v-for="(airport, index) in airports" :key="index">
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title pr-4 flex-fill">
                {{ airport.name }}
              </div>
              <div class="option-focus d-flex flex-fill justify-content-end">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeAirport(airport.id)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 pt-0">
              {{ airport.address }}
            </span>
          </span>
        </div>
      </div>
    </div>

     <!--Begin: Add new airport drop off region modal-->
    <b-modal id="modal-add-airport-dropoff-region" ref="modal-add-airport-dropoff-region" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Havalimanı girin</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div class="form-group">
          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="flaticon2-location"></i>
              </span>
            </div>
            <gmap-autocomplete
              class="form-control form-control-lg"
              placeholder="Bir Havalimanı girin"
              @place_changed="setPlace"
              :options="{
                types: ['establishment']
              }"
            >
            </gmap-autocomplete>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.place == null">
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new airport drop-off region modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  name: 'ExperienceWizardAirportDropOff',
  props: ['experienceId'],
  data() {
    return {
      airports: [],
      addItemModal: {
        place: null
      },
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  mounted() {
    this.getAirports();
  },
  methods: {
    getAirports() {
      ApiService.get(`experience/drop-off/airport/regions/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.airports = data.airports;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    setPlace(place) {
      this.addItemModal.place = place;
    },

    handleAddItemModalCancel() {
      this.addItemModal.place = null;
    },

    handleAddItemModalOk() {
      const place = this.addItemModal.place;
      const data = {
        pid: this.experienceId,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
        name: place.name
      }

      ApiService.post('experience/drop-off/airport/regions/add.req.php', data)
      .then(({ data }) => {
        const newAirport = {
          id: data.airportId,
          address: place.formatted_address,
          name: place.name
        }
        this.airports = [newAirport];
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    removeAirport(regionID) {
      const data = {
        regionID,
        pid: this.experienceId
      }

      ApiService.post('experience/drop-off/airport/regions/remove.req.php', data)
      .then(() => {
        this.airports = [];
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
      
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 100000;
  border-radius: 8px;
}
.pac-item {
  padding: 8px;
}
</style>