<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Vitrin fiyatı</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="d-flex align-items-center flex-wrap mb-8">
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <div v-if="data.discount.status">
            <span class="text-muted font-weight-bold font-size-h6 pr-1"><del>{{ data.price }} TRY</del></span>
            <span class="text-primary font-weight-bold font-size-h6 pl-1">% {{ data.discount.rate }} İndirim</span>
          </div>
          <span class="font-weight-bold text-body font-size-h4 mb-0" v-if="data.discount.status">{{ data.discount.price }} TRY / <span class="text-body font-weight-normal font-size-h6">{{ data.priceDescription[lang] }}</span></span>
          <span class="font-weight-bold text-body font-size-h4 mb-1" v-else>{{ data.price }} TRY / <span class="text-body font-weight-normal font-size-h6">{{ data.priceDescription[lang] }}</span></span>
          <span class="text-body font-weight-bold font-size-lg" v-if="false">{{ data.priceDescription.tr }}</span>
        </div>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data", "lang"]
}
</script>

<style>

</style>