<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Önemli bilgiler</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div style="width: 80%">
      <p class="text-body font-size-h6 font-weight-normal experience-content-importants" v-html="importants[lang]"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["importants", "lang"],
}
</script>

<style>
.experience-content-importants ul {
  margin-left: 2rem !important;
}
</style>