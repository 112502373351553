<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Fotoğrafları düzenleyin
    </h1>
    <p class="font-size-h5 mb-12">
      Deneyiminize katılmanın nasıl bir şey olduğunu misafirlere göstermek için en az 6 adet yüksek kaliteli fotoğraf ekleyin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <h4 class="text-dark pb-3">Kapak fotoğrafı</h4>
    <div class="symbol photo-upload-wrapper">
      <span class="symbol-label bg-white w-450px h-225px" v-show="coverPhoto !== ''">
        <img :src="coverPhoto" class="w-100 rounded foreground-img">
        <img :src="coverPhoto" class="w-100 rounded shadow-img">
        <b-button v-b-tooltip.hover type="button" @click="$refs.coverPhotoUploader.click()" class="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow btn-action" data-toggle="tooltip" title="Kapak fotoğrafını değiştir">
          <i class="flaticon2-edit icon-md text-muted"></i>
        </b-button>
        <div class="photo-overlay p-8 d-flex flex-column align-items-center justify-content-center rounded" v-if="fileUpload.coverPhotoUploader.isUploading">
          <div class="spinner spinner-white mr-3"></div>
        </div>
      </span>
      <span class="symbol-label w-300px h-150px uploader" :class="[{'bg-white bg-hover-light': !coverError.isCovePhotoInvalid}, {'bg-light-warning invalid': coverError.isCovePhotoInvalid}]" @click="$refs.coverPhotoUploader.click()" v-show="coverPhoto === ''">
        <i class="la la-photo icon-3x text-dark"></i>
        <input type="file" ref="coverPhotoUploader" accept="image/png, image/jpeg" @change="handleFileUpload('coverPhotoUploader')">
      </span>
    </div>


    <h4 class="text-dark pb-3 mt-12">Galeri fotoğrafları</h4>
    <div class="alert alert-warning bg-light-warning mt-4 mb-8" role="alert" v-if="galleryError.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ galleryError.message }}</span>
      </div>
    </div>
    
    <draggable v-model="gallery" v-bind="dragOptions"  @start="drag = true" @end="dragEnded">
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div class="symbol photo-upload-wrapper mr-8 mb-8" v-for="(photo, index) in gallery" :key="`photo_${index}`">
            <span class="symbol-label bg-white w-225px h-150px">
              <img :src="photo.image" class="w-100 rounded">
              <b-button v-b-tooltip.hover @click="removeForm.photoID = photo.id" type="button" class="btn btn-sm btn-circle btn-icon btn-white btn-hover-icon-primary btn-shadow btn-action" data-toggle="tooltip" title="Fotoğrafı kaldır">
                <i class="flaticon2-trash icon-md text-muted"></i>
              </b-button>
              <div class="photo-overlay p-8 d-flex flex-column align-items-center justify-content-center rounded" v-if="removeForm.photoID == photo.id">
                <div class="d-flex flex-column flex-wrap justify-content-center align-items-center" v-if="!removeForm.isRemoving">
                  <button type="button" class="btn btn-sm font-weight-bolder btn-primary mb-2" @click="removeGalleryPhoto">Fotoğrafı kaldır!</button>
                  <button type="button" class="btn btn-sm font-weight-bolder btn-hover-transparent-white" @click="removeForm.photoID = 0">İptal et</button>
                </div>
                <div class="spinner spinner-white mr-3" v-else></div>
              </div>
            </span>
          </div>
      </transition-group>
    </draggable>
    <div class="symbol photo-upload-wrapper">
      <span class="symbol-label w-225px h-150px uploader" :class="[{'bg-white bg-hover-light': !galleryError.isGalleryPhotoInvalid}, {'bg-light-warning invalid': galleryError.isGalleryPhotoInvalid}]" @click="$refs.galleryPhotoUploader.click()">
        <div
          class="spinner spinner-dark mr-3"
          v-if="fileUpload.galleryPhotoUploader.isUploading"
        ></div>
        <i class="la la-photo icon-3x text-dark" v-else></i>
        <input type="file" ref="galleryPhotoUploader" accept="image/png, image/jpeg" @change="handleFileUpload('galleryPhotoUploader')">
      </span>
    </div>

    <label class="text-muted pt-30">By uploading these photos, I verify that I am the owner or lawful licensee of the copyrights in these photographs and I accept the <strong>Zenofon Supplier Agreement</strong> & <strong>Terms of Use</strong>.</label>

    <!--Begin: Error message modal-->
    <b-modal id="modal-error-message" ref="modal-error-message"  centered header-class="py-3" footer-class="py-3" @cancel="resetErrorModal" @hide="resetErrorModal" @ok="resetErrorModal">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>{{ modalError.title }}</h5>
          </div>
        </div>
      </template>

      <p class="font-size-h6">
        {{ modalError.message }}
      </p>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok"
          >
            Tamam
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Error message modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Draggable from 'vuedraggable';

export default {
  name: 'ExperienceEditorPhotos',
  props: ['experienceId'],
  components: {
    Draggable
  },
  data() {
    return {
      drag: false,
      coverPhoto: '',
      gallery: [],
      fileUpload: {
        coverPhotoUploader: {
          width: 1585,
          height: 792,
          uploadUrl: '',
          isUploading: false
        },
        galleryPhotoUploader: {
          width: 1200,
          height: 800,
          uploadUrl: '',
          isUploading: false
        }
      },
      modalError: {
        title: '',
        message: ''
      },
      coverError: {
        status: false,
        isCovePhotoInvalid: false,
        message: 'Lütfen deneyiminize bir kapak fotoğrafı ekleyin.',
      },
      galleryError: {
        status: false,
        isGalleryPhotoInvalid: false,
        message: 'Lütfen deneyiminize en az 6 adet fotoğraf ekleyin.',
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir tema seçin.',
      },
      removeForm: {
        photoID: 0,
        isRemoving: false
      },
      isSaving: false,
      isSwaping: false
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {
    this.getPhotos();
  },
  methods: {
    getPhotos() {
      ApiService.get(`experience/photo/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.coverPhoto = data.cover;
        this.gallery = data.gallery;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setCoverPhoto(file) {
      this.fileUpload.coverPhotoUploader.isUploading = true;
      let formData = new FormData();
      formData.append('id', this.experienceId);
      formData.append('cover', file);

      ApiService.post('experience/photo/cover/set.req.php', formData)
      .then(({ data }) => {
        this.coverPhoto = data.cover;
        this.fileUpload.coverPhotoUploader.isUploading = false;
        this.coverError.isCovePhotoInvalid = false;
      })
      .catch(({ response }) => {
        this.fileUpload.coverPhotoUploader.isUploading = false;
        this.modalError.title = response.data.error;
        this.modalError.message = response.data.errorMessage;
        this.$bvModal.show('modal-error-message');
      });
    },

    addGalleryPhoto(file) {
      this.fileUpload.galleryPhotoUploader.isUploading = true;
      let formData = new FormData();
      formData.append('id', this.experienceId);
      formData.append('photo', file);

      ApiService.post('experience/photo/gallery/add.req.php', formData)
      .then(() => {
        this.getPhotos();
        this.fileUpload.galleryPhotoUploader.isUploading = false;
        this.galleryError.status = false;
        this.galleryError.isGalleryPhotoInvalid = false;
      })
      .catch(({ response }) => {
        this.fileUpload.galleryPhotoUploader.isUploading = false;
        this.modalError.title = response.data.error;
        this.modalError.message = response.data.errorMessage;
        this.$bvModal.show('modal-error-message');
      });
    },

    removeGalleryPhoto() {
      this.removeForm.isRemoving = true;
      const photoID = this.removeForm.photoID;
      const photo = this.gallery.filter(function(el) {
        return el.id == photoID
      })[0];

      const data= {
        id: this.experienceId,
        photoID: photoID,
        order: photo.order
      }

     ApiService.post('experience/photo/gallery/remove.req.php', data)
      .then(() => {
        this.removeForm.photoID = 0;
        this.removeForm.isRemoving = false;
        this.getPhotos();
      })
      .catch(({ response }) => {
        this.removeForm.photoID = 0;
        this.removeForm.isRemoving = false;
        this.modalError.title = response.data.error;
        this.modalError.message = response.data.errorMessage;
        this.$bvModal.show('modal-error-message');
      });
    },

    handleFileUpload(inputRef) {
      const self = this;
      const file = this.$refs[inputRef].files[0];
      const preferredWidth = this.fileUpload[inputRef].width;
      const preferredHeight = this.fileUpload[inputRef].height;

      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();
        
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height != preferredHeight || width != preferredWidth) {
            self.modalError.title = 'Fotoğraf çözünürlüğü hatalı';
            self.modalError.message = `Fotoğraf ${preferredWidth} piksel genişliğe ve ${preferredHeight} piksel yüksekliğe sahip olmalıdır. Lütfen doğru çözünürlüklü bir fotoğraf yükleyin. Fotoğrafınızın çözünürlüğü ${width}x${height}.`
            self.$bvModal.show('modal-error-message');
          } else {
            if (inputRef == 'coverPhotoUploader') {
              self.setCoverPhoto(file);
            } else {
              self.addGalleryPhoto(file);
            }
          }
        };
      }
    },

    resetErrorModal() {
      this.modalError.status = false;
      this.modalError.message = '';
    },

    dragEnded(e) {
      this.drag = false;
      if (e.newIndex == e.oldIndex) {
        return;
      }
      this.isSwaping = true;
      const index = e.newIndex;
      const photo = this.gallery[index];
      const newOrder = e.newIndex + 1;

      const data = {
        pid: this.experienceId,
        photoID: photo.id,
        oldOrder: photo.order,
        newOrder: newOrder
      }
      ApiService.post('experience/photo/gallery/swap.req.php', data)
      .then(() => {
        this.isSwaping = false;
        this.getPhotos();
      })
      .catch(({ response }) => {
        this.isSwaping = false;
        this.modalError.title = response.data.error;
        this.modalError.message = response.data.errorMessage;
        this.$bvModal.show('modal-error-message');
      });
    }
  },
};
</script>

<style scoped>
.photo-upload-wrapper .symbol-label {
  border: 2px dashed transparent;
}
.photo-upload-wrapper .uploader {
  cursor: pointer;
  border-color: var(--secondary);
}
.photo-upload-wrapper .uploader.invalid {
  border-color: var(--warning);
}
.photo-upload-wrapper {
  width: auto !important;
  height: 100% !important;
}
.photo-upload-wrapper input {
  display: none;
}
.photo-upload-wrapper .symbol-label .btn-action {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  z-index: 2;
}
.photo-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background: rgba(0,0,0,0.15);
}
.photo-upload-wrapper .symbol-label:hover .btn-action {
  display: block;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}

.foreground-img {
  z-index: 1;
}
.shadow-img {
  position: absolute;
  filter: blur(30px); 
  opacity: 0.5; 
  margin-top: 30px;
}
</style>
