<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Katılımcılar</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>
    
    <div>
      <div class="d-flex align-items-center flex-wrap mb-8" v-for="(item, index) in participants" :key="index">
        <div class="symbol symbol-50 symbol-light mr-5">
          <span class="symbol-label">
            <inline-svg :src="participantDetails[item.id].avatar" />
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <span class="font-weight-bold text-body font-size-h6 mb-1">{{ participantDetails[item.id].title }}</span>
          <span class="text-muted font-weight-normal font-size-lg">{{ item.age }}</span>
        </div>
      </div>
    </div>
    
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["participants"],
  data() {
    return {
      participantDetails: {
        passenger: {
          title: "Yolcu",
          avatar: "/media/svg/participants/passenger.svg"
        },
        elderly: {
          title: "Yaşlı",
          avatar: "/media/svg/participants/elderly.svg"
        },
        adult: {
          title: "Yetişkin",
          avatar: "/media/svg/participants/adult.svg"
        },
        youth: {
          title: "Genç",
          avatar: "/media/svg/participants/youth.svg"
        },
        student: {
          title: "Öğrenci",
          avatar: "/media/svg/participants/student.svg"
        },
        child: {
          title: "Çocuk",
          avatar: "/media/svg/participants/child.svg"
        },
        child_2: {
          title: "Çocuk 2",
          avatar: "/media/svg/participants/child_2.svg"
        },
        infant: {
          title: "Bebek",
          avatar: "/media/svg/participants/infant.svg"
        },
      },
    }
  }
}
</script>

<style>

</style>