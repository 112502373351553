<template>
  <transition name="section" leave-active-class="hidden">
    <div class="overlay-modal">
      <div class="card card-custom card-fit pt-10">
        <div class="card-header" v-if="false">
          <div class="card-title">
            <h4 class="font-weight-bold text-dark mb-0">{{ title }}</h4>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-sm btn-light font-weight-bold" style="position: absolute; top: 20px; left:20px;" @click="close">
              <i class="flaticon2-cross icon-xs"></i>
              Kapat
            </button>
          </div>
        </div>
        <div class="card-body pt-0 mb-10">
          <button class="btn btn-icon btn-light btn-circle font-weight-bold" style="position: absolute; top: 15px; left:15px;z-index:1" @click="close">
              <i class="flaticon2-cross icon-md"></i>
            </button>
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
  
</template>

<script>
export default {
  props: ["title"],
  methods: {
    close() {
      this.hidden = true;
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.overlay-modal {
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: slideUp .8s ease forwards;
  overflow: scroll;
}
.overlay-modal.hidden {
  animation: slideDown .8s ease forwards;
}
.card {
  height: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@keyframes slideUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

</style>