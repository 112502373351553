<template>
  <div>
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button 
        class="button-hold fill font-weight-bold font-size-h6" 
        :class="[{'process': removeButton.isProcessing}, {'success': removeButton.isSuccess}]" 
        @mousedown="startProcessing" 
        @mouseup="endProcessing"
      >
        <ul>
          <li class="d-flex align-items-center justify-content-center">
              <i class="flaticon2-rubbish-bin text-white pr-2"></i>
              Deneyimi Sil
          </li>
          <li>Emin misiniz?</li>
          <li>Siliniyor...</li>
        </ul>
      </button>
      <p class="font-weight-normal font-size-lg text-muted py-2">Deneyimi silmeniz durumunda bu işlem geri alınamaz.</p>
    </div>
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data() {
    return {
      removeButton: {
        duration: 2000,
        timeout: null,
        isProcessing: false,
        isSuccess: false
      },
      error: {
        status: false,
        message: '',
      }
    }
  },
  methods: {
    startProcessing() {
      const self = this;
      this.removeButton.isProcessing = true;
      this.removeButton.timeout = setTimeout(() => {
        self.removeButton.isSuccess = true;
        self.remove();
      }, self.removeButton.duration);
    },
    endProcessing() {
      this.removeButton.isProcessing = false;
      clearTimeout(this.removeButton.timeout);
    },
    remove() {
      const self = this;
      setTimeout(() => {
        self.removeExperience();
      }, 1000);
    },
    removeExperience() {
      const data = {
        id: this.experienceId,
        status: 0
      }
      ApiService.post('experience/status/set.req.php', data)
      .then(() => {
        this.$router.push({ name: "dashboard" });
      })
      .catch(({ response }) => {
        this.removeButton.isProcessing = false;
        this.removeButton.isSuccess = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  }
}
</script>

<style scoped>
.button-hold {
  --color: #F6F8FF;
  --background: var(--primary);
  --shadow: rgba(0, 9, 61, 0.2);
  --progress: var(--secondary);
  --outer-border: #A6ACCD;
  --fill: #EE2D41;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 12px 0;
  border: 0;
  width: 240px;
  border-radius: 0.85rem;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-appearance: none;
  transition: box-shadow 0.3s, transform 0.3s;
  /*box-shadow: 0 var(--shadow-y, 4px) var(--shadow-blur, 12px) var(--shadow);*/
  color: var(--color);
  background: var(--b, var(--background));
  transform: scale(var(--scale, 1));
  --duration: 2000ms;
}
.button-hold ul {
  margin: 0;
  padding: 0;
  text-align: center;
  pointer-events: none;
  list-style: none;
  position: relative;
  z-index: 3;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.button-hold ul li {
  top: var(--t, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateY(var(--ul-y)) translateZ(0);
  transition: transform 0.3s, opacity 0.2s;
}
.button-hold ul li:not(:first-child) {
  --o: 0;
  position: absolute;
  left: 0;
  right: 0;
}
.button-hold ul li:nth-child(1) {
  opacity: var(--ul-o-1, 1);
}
.button-hold ul li:nth-child(2) {
  --t: 100%;
  opacity: var(--ul-o-2, 0);
}
.button-hold ul li:nth-child(3) {
  --t: 200%;
  opacity: var(--ul-o-3, 0);
}
.button-hold:focus:not(.process):not(.success), .button-hold:hover:not(.process):not(.success) {
  --shadow-y: 8px;
  --shadow-blur: 16px;
}
.button-hold:active:not(.success) {
  --shadow-y: 4px;
  --shadow-blur: 8px;
}
.button-hold.process {
  --ul-y: -100%;
  --ul-o-1: 0;
  --ul-o-2: 1;
  --ul-o-3: 0;
}
.button-hold.success {
  --ul-y: -200%;
  --ul-o-1: 0;
  --ul-o-2: 0;
  --ul-o-3: 1;
}
.button-hold.outer svg, .button-hold.inner svg {
  top: 0;
  left: 0;
  width: 120px;
  height: 43px;
  stroke: var(--progress);
  stroke-width: 4px;
  fill: none;
  position: absolute;
  display: block;
  stroke-linecap: round;
  stroke-dasharray: var(--array, 282) 284;
  stroke-dashoffset: var(--offset, 282);
  z-index: var(--i, 1);
  transition: stroke-dashoffset var(--duration) linear, stroke-dasharray 0.4s linear;
}
.button-hold.outer.process, .button-hold.outer.success, .button-hold.inner.process, .button-hold.inner.success {
  --offset: 0;
  --array: 284;
}
.button-hold.outer {
  --b: var(--outer-border);
}
.button-hold.outer:before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
  border-radius: inherit;
  transition: transform 0.3s;
  background: var(--background);
  transform: scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
}
.button-hold.outer:active:not(.success) {
  --scale-x: .94;
  --scale-y: .84;
}
.button-hold.outer.success:before {
  transition: box-shadow 0.5s, transform 0.3s;
  box-shadow: inset 0 0 0 24px var(--progress);
}
.button-hold.inner svg {
  --i: 3;
}
.button-hold.inner.success {
  transition: box-shadow 0.5s, transform 0.3s;
  box-shadow: 0 var(--shadow-y, 4px) var(--shadow-blur, 12px) var(--shadow), inset 0 0 0 24px var(--progress);
}
.button-hold.fill {
  --b: linear-gradient(90deg, var(--progress) 0%, var(--progress) 33.33%, var(--fill) 33.33%, var(--fill) 66.66%, var(--background) 66.66%, var(--background) 100%) left var(--p, 100%) top 0;
  background-position-x: var(--p, 100%);
  background-size: 300% 100%;
  transition: background-position var(--duration) linear, box-shadow 0.3s, transform 0.3s;
}
.button-hold.fill:active:not(.success) {
  --scale: .94;
}
.button-hold.fill.process, .button-hold.fill.success {
  --p: 50%;
}
.button-hold.fill.success {
  --p: 0;
  transition: background 0.5s, box-shadow 0.3s, transform 0.3s;
  color: var(--gray-dark);
}
</style>