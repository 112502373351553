<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Fotoğraflar</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>
    
    <div class="d-flex flex-wrap">
      <div class="symbol photo-upload-wrapper mr-8 mb-4" v-for="(photo, index) in data.gallery" :key="index">
        <span class="symbol-label bg-white w-225px h-150px">
          <img :src="photo.image" class="w-100 rounded">
          <div class="photo-overlay p-8 d-flex flex-column align-items-center justify-content-center rounded" v-if="data.gallery.length == index+1 && data.numOfRestImage > 0">
            <div class="d-flex flex-column flex-wrap justify-content-center align-items-center">
              <span class="text-white font-weight-bold display-3">+{{ data.numOfRestImage }}</span>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style scoped>
.photo-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
}
</style>