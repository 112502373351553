<template>
  <div>
    <div class="d-flex flex-column align-items-center justify-content-center" v-if="reviews.length == 0 && numberOfPending == 0">
      <img src="media/svg/illustrations/no-message.svg" style="width:30%"/>
      <label class="d-block font-weight-bold font-size-h6 text-muted text-center py-5">Herhangi bir değerlendirme bulunamadı.</label> 
    </div>
    <div v-else>
      <ReviewItem v-for="(review, index) in reviews" :key="index" :review="review">
        <template v-slot:dropdown>
          <b-dropdown 
            size="sm"
            variant="light-primary"
            class="ml-4"
            toggle-class="btn btn-light btn-icon btn-sm font-weight-bold dropdown-toggle"
            right
            no-flip
            no-caret
          >
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor"></i>
          </template>
            <b-dropdown-item @click="setReview(review.id, 2)"><span class="text-dark-50">Beklemeye al</span></b-dropdown-item>
            <b-dropdown-item @click="setReview(review.id, 0)" variant="primary">Reddet</b-dropdown-item>
          </b-dropdown>
        </template>
      </ReviewItem>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import ReviewItem from "@/view/pages/experience/components/review/ReviewItem";
export default {
  props: ["experienceId", "numberOfPending"],
  components: {
    ReviewItem
  },
  data() {
    return {
      reviews: []
    }
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    getReviews() {
      ApiService.get(`experience/review/confirmed/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.reviews = data.reviews;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

     setReview(reviewId, status) {
      const data = {
        reviewId,
        status,
        id: this.experienceId 
      }

      ApiService.post('experience/review/set.req.php', data)
      .then(() => {
        this.getReviews();
        if (status == 2) {
          this.$emit('revertToPending');
        }
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    }

  }
}
</script>

<style>

</style>