<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Durum</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>
    
    <div class="form-group">
      <div class="d-flex align-items-center flex-wrap mb-8">
        <div class="symbol symbol-50 symbol-light mr-5">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-2x" :class="statusIconColor">
              <inline-svg :src="statusIcon"/>
            </span>
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <span class="font-weight-bold font-size-h6" :class="statusTextColor">{{ statusLabelText }}</span>
        </div>
      </div>
    </div> 

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["status"],
  computed: {
    ...mapGetters(["experienceConfig"]),

    statusIconColor() {
      return 'svg-icon-'+this.experienceConfig(`status.${this.status}.color`);
    },
    statusTextColor() {
      return 'text-'+this.experienceConfig(`status.${this.status}.color`);
    },
    statusIcon() {
      return this.experienceConfig(`status.${this.status}.icon`);
    },
    statusLabelText() {
      return this.experienceConfig(`status.${this.status}.text`);
    },
  }
}
</script>