<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Temel bilgiler</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Hareket yeri: <span class="font-weight-normal">{{ data.departurePoint[lang] }}</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Başlık: <span class="font-weight-normal">{{ data.title[lang] }}</span></span>
      </div>  
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data", "lang"]
}
</script>

<style>

</style>