<template>
  <div class="card card-custom gutter-b card-stretch">
    <div class="card-header h-auto border-0">
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Satışlar</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark" role="tablist">
          <li class="nav-item">
            <button
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'lastSix' }"
              @click="show = 'lastSix'"
            >
              <span class="nav-text font-weight-bolder font-size-sm">6 Aylık</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'lastThree' }"
              @click="show = 'lastThree'"
            >
              <span class="nav-text font-weight-bolder font-size-sm">3 Aylık</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <LastSixMonthSales :experience-id="experienceId" v-show="this.show === 'lastSix'" />
      <LastThreeMonthSales :experience-id="experienceId" v-show="this.show === 'lastThree'" />
    </div>
  </div>
</template>

<script>
import LastSixMonthSales from "@/view/pages/experience/components/overview/LastSixMonthSales";
import LastThreeMonthSales from "@/view/pages/experience/components/overview/LastThreeMonthSales";

export default {
  props: ["experienceId"],
  components: {
    LastSixMonthSales,
    LastThreeMonthSales
  },
  data() {
    return {
      show: 'lastThree',
    }
  }
}
</script>

<style>

</style>