<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Deneyimi birkaç cümle ile anlat
    </h1>
    <p class="font-size-h5 mb-7">
      Bu deneyimde misafirleri nelerin beklediğini çok fazla detaya inmeden
      anlatabilirsiniz.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
      <textarea
        class="form-control form-control-solid font-size-lg mb-2"
        rows="4"
        placeholder="Misafirleri bu deneyimde neler bekliyor?"
        :maxlength="maxSummaryLength"
        v-model.trim="summary.tr"
      ></textarea>
      <span class="text-muted pl-1">
        {{ summary.tr.length }} / {{ maxSummaryLength }}
      </span>
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
      <textarea
        class="form-control form-control-solid font-size-lg mb-2"
        rows="4"
        placeholder="Misafirleri bu deneyimde neler bekliyor?"
        :maxlength="maxSummaryLength"
        v-model.trim="summary.en"
      ></textarea>
      <span class="text-muted pl-1">
        {{ summary.en.length }} / {{ maxSummaryLength }}
      </span>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setSummary"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorSummary',
  props: ['experienceId'],
  data() {
    return {
      summary: {
        tr: '',
        en: '',
      },
      error: {
        status: false,
        message: '',
      },
      isSaving: false,
      maxSummaryLength: 300,
    };
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.summary.tr.length == 0 || this.summary.en.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      ApiService.get(`experience/summary/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.summary.tr = data.summary.tr;
        this.summary.en = data.summary.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setSummary() {
      this.isSaving = true;
      const data = JSON.stringify({
        id: this.experienceId,
        summary: JSON.stringify(this.summary)
      });

      ApiService.post('experience/summary/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
};
</script>

<style></style>
