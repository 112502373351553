<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Vitrin fiyatını düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizin ücretini girin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div>
      <div class="form-group d-flex flex-row justify-content-between">
        <b-form-radio-group
          :options="languageOptions"
          v-model="selectedLanguage"
          buttons
          button-variant="outline-secondary"
          name="radios-btn-language"
        ></b-form-radio-group>
      </div>

      <div class="form-group">
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                <div v-if="price.discount.status">
                  <span class="text-muted pr-1"><del>{{ price.amount }} TRY</del></span>
                  <span class="text-primary pl-1">% {{ price.discount.rate }} İndirim</span>
                </div>
                <span v-if="price.discount.status">{{ price.discount.discountedAmount }} TRY</span>
                <span v-else>{{ price.amount }} TRY</span>
              </div>
              <div class="option-focus">
                <b-button
                  v-if="price.discount.status"
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-light font-weight-bold btn-sm mr-3"
                  data-toggle="tooltip"
                  title="İndirimi kaldır"
                  @click="removeDiscount()"
                >
                  İndirimi kaldır
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditPriceModal()"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 mw-80 pt-0">
              {{ price.description[selectedLanguage] }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaving"
          @click="setPrice"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

    <!--Begin: Edit duration modal-->
    <b-modal id="modal-edit-price-item" ref="modal-edit-price-item" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.activeStep }} ADIM/2</label>
            <h5>{{ editItemModal.steps[editItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="editItemModal.activeStep === 1">
          <div :class="[{'form-group': editItemModal.price.discount}, {'mb-2': !editItemModal.price.discount}]">
            <label class="text-body font-weight-bold pl-1">Orijinal fiyat</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TRY
                </span>
              </div>
              <input
                type="number"
                class="form-control form-control-lg"
                placeholder="Vitrin fiyatını girin"
                ref="editPriceModalAmount"
                v-model="editItemModal.price.amount"
              >
            </div>
          </div>
          <div class="form-group" v-if="!editItemModal.price.discount">
            <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" @click="editItemModal.price.discount = true">
              <i class="flaticon2-add-1 icon-sm"></i>
              <span>İndirim ekle</span>
            </button>
          </div>
          <div class="row" v-else>
            <div class="col-md-7">
              <div class="form-group">
                <label class="text-body font-weight-bold pl-1">İndirimli fiyat</label>
                <div class="input-group input-group-lg">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bold text-dark">
                      TRY
                    </span>
                  </div>
                  <input
                    type="number"
                    class="form-control form-control-lg"
                    placeholder="İndirimli fiyatı girin"
                    ref="editPriceModalDiscountedAmount"
                    v-model="editItemModal.price.discountedAmount"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label class="text-body font-weight-bold pl-1">İndirim oranı</label>
                <div class="input-group input-group-lg">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bold text-dark">
                      %
                    </span>
                  </div>
                  <input
                    type="number"
                    class="form-control form-control-lg"
                    placeholder="İndirim oranı"
                    ref="editPriceModalDiscountRate"
                    v-model="editItemModal.price.discountRate"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="editItemModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe açıkla</label>
            <input
              type="text"
              class="form-control form-control-lg mb-2"
              placeholder="Ör: kişi başı"
              ref="editPriceModalDescTR"
              v-model="editItemModal.price.description.tr"
            >
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce açıkla</label>
            <input
              type="text"
              class="form-control form-control-lg mb-2"
              placeholder="Ör: per person"
              ref="editPriceModalDescEN"
              v-model="editItemModal.price.description.en"
            >
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editItemModal.activeStep = editItemModal.activeStep - 1" 
            v-if="editItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editItemModal.price.description.tr == '' ||editItemModal.price.description.en == ''"
            v-if="editItemModal.activeStep == editItemModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editItemModal.price.amount == ''"
            @click="editItemModal.activeStep = editItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit duration modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorPrice',
  props: ['experienceId'],
  data() {
    return {
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      price: {
        status: false,
        discount: {
          status: false,
          rate: 0,
          discountedAmount: 0
        },
        amount: '',
        description: {
          tr: '',
          en: ''
        }
      },
      editItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Fiyat girin'
          },
          {
            number: 2,
            title: 'Fiyatı açıklayın'
          }
        ],
        price: {
          amount: 0,
          discount: false,
          discountedAmount: 0,
          discountRate: 0,
          description: {
            tr: '',
            en: ''
          }
        }
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen vitrin fiyatı girin.',
      },
      isSaving: false
    }
  },
  watch: {
    'editItemModal.price.amount': function (originalPrice) {
      const discountedPrice = this.editItemModal.price.discountedAmount;
      this.editItemModal.price.discountRate = parseInt (((originalPrice - discountedPrice) / originalPrice) * 100);
    },
    'editItemModal.price.discountedAmount': function (discountedPrice) {
      const originalPrice = this.editItemModal.price.amount;
      this.editItemModal.price.discountRate = parseInt (((originalPrice - discountedPrice) / originalPrice) * 100);
    },
  },
  mounted() {
    this.getPrice();
  },
  methods: {
    getPrice() {
      ApiService.get(`experience/price/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.price.status = data.priceStatus == 1;
        this.price.amount = data.priceAmount;
        this.price.discount.status = data.discount.status == 1;
        this.price.discount.rate = data.discount.rate;
        this.price.discount.discountedAmount = data.discount.price;
        this.price.description = data.priceDesc;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setPrice() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        price: this.price.amount,
        discountStatus: this.price.discount.status ? 1 : 0,
        discountRate: this.price.discount.rate,
        discountedPrice: this.price.discount.discountedAmount,
        priceDesc: JSON.stringify(this.price.description),
      }
      ApiService.post('experience/price/edit.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    openEditPriceModal() {
      this.editItemModal.price.amount = this.price.amount;
      this.editItemModal.price.discount = this.price.discount.status;
      this.editItemModal.price.discountRate = this.price.discount.rate;
      this.editItemModal.price.discountedAmount = this.price.discount.discountedAmount;
      this.editItemModal.price.description.tr = this.price.description.tr;
      this.editItemModal.price.description.en = this.price.description.en;
      this.$bvModal.show('modal-edit-price-item');
    },

    handleEditItemModalOk() {
      this.price.amount = this.editItemModal.price.amount;
      this.price.discount.status = this.editItemModal.price.discount;
      this.price.discount.rate = this.editItemModal.price.discountRate;
      this.price.discount.discountedAmount = this.editItemModal.price.discountedAmount;
      this.price.description = this.editItemModal.price.description;
    },

    handleEditItemModalCancel() {
      this.editItemModal.activeStep = 1;
      this.editItemModal.price.amount = "";
      this.editItemModal.price.discount = false;
      this.editItemModal.price.discountRate = 0;
      this.editItemModal.price.discountedAmount = 0;
      this.editItemModal.price.description = { tr: '', en: '' };
    },

    removeDiscount() {
      this.price.discount.status = false;
      this.price.discount.rate = 0;
      this.price.discount.discountedAmount = 0;
    }
  }
}
</script>

<style>

</style>