<template>
  <div class="mt-20">
    <h5 class="font-weight-bold text-dark pb-2">
      Otelden alımlar için coğrafi bölge tanımlayın
    </h5>
    <div class="form-group d-flex flex-row align-items-center justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" v-if="regions.length <= 15" @click="$bvModal.show('modal-add-hotel-pickup-region')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="regions.length == 0">Bir bölge ekle</span>
        <span v-else>Başka bir bölge ekle</span>
      </button>
      <label class="font-weight-bold text-muted pt-3" v-else><em>Daha fazla bölge ekleyemezsiniz</em></label>
      <ServiceFee :experience-id="experienceId" v-if="regions.length > 0 && serviceFee"></ServiceFee>
    </div>

    <div>
      <div
        class="form-group"
        v-for="(region, index) in regions"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-control mr-4" style="width: auto;">
            <div class="symbol">
              <span class="symbol-label">{{ index + 1 }}</span>
            </div>
          </span>
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title pr-4 flex-fill">
                {{ region.address }}
              </div>
              <div class="option-focus d-flex flex-fill justify-content-end">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Çapı değiştir"
                  @click="openEditRegionModal(region.id)"
                >
                  <i class="flaticon2-edit"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeRegion(index)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 pt-0">
              <span 
                class="label label-dot label-danger mb-1 mr-3 label-xl" ></span>
              <span>{{ region.radius / 1000 }} km çapındaki oteller</span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <gmap-map
      v-if="regions.length > 0"
      ref="map"
      :options="map.options"
      :center="map.center"
      :zoom="map.zoom"
      style="width:100%;  height: 400px;"
    >
      <gmap-circle
        v-for="(pin, index) in regions"
        :key="index"
        :center="pin.position"
        :visible="true"
        :options="map.circleOptions"
        :radius="pin.radius"
      ></gmap-circle>

      <gmap-marker
        v-for="(region, index) in regions"
        :key="`marker_${index}`"
        :position="region.position"
        :clickable="true"
        :draggable="true"
        :label="String(index + 1)"
        @click="map.center=region.position"
        @dragend="updateCoordinates($event, index)"
      />
    </gmap-map>



    <!--Begin: Add new hotel pick up region modal-->
    <b-modal id="modal-add-hotel-pickup-region" ref="modal-add-hotel-pickup-region" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/2</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addItemModal.activeStep === 1">
          <div class="form-group">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Otelden alınma bölgesi girin"
                ref="addRegionModalAddress"
                @place_changed="setPlace">
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div v-show="addItemModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Kaç km çapındaki alanda hizmet vereceksiniz?</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  KM
                </span>
              </div>
              <input
                type="number"
                class="form-control form-control-lg"
                placeholder="Bölgenin çapı"
                ref="addRegionModalRadius"
                v-model="addItemModal.radius"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.radius == 0 || addItemModal.radius == ''"
            v-if="addItemModal.activeStep == addItemModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.place == null"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new hotel pick up region modal-->

    <!--Begin: Edit hotel pick up region modal-->
    <b-modal id="modal-edit-hotel-pickup-region" ref="modal-edit-hotel-pickup-region" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Bölgenin çapını girin</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1">Kaç km çapındaki alanda hizmet vereceksiniz?</label>
          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text font-weight-bold text-dark">
                KM
              </span>
            </div>
            <input
              type="number"
              class="form-control form-control-lg"
              placeholder="Bölgenin çapı"
              ref="editRegionModalRadius"
              v-model="editItemModal.selectedRegionRadius"
            />
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editItemModal.radius == 0 || editItemModal.radius == ''"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new hotel pick up region modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';
import ServiceFee from '@/view/pages/experience/components/wizard/pickup/hotel/ServiceFee';
export default {
  name: 'ExperienceWizardHotelPickUp',
  props: ['experienceId', 'serviceFee'],
  components: {
    ServiceFee
  },
  data() {
    return {
      map: {
        center: { lat: 41.0055066, lng: 39.731024 },
        zoom: 10,
        options:{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false
        },
        circleOptions: {
          strokeWeight: 0,
          fillColor: '#FF0000',
          fillOpacity: 0.2,
        }
      },
      regions: [],
      addItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Coğrafi bölge ekleyin'
          },
          {
            number: 2,
            title: 'Bölgenin çapını girin'
          }
        ],
        place: null,
        radius: 1
      },
      editItemModal: {
        selectedRegionId: null,
        selectedRegionRadius: ''
      },
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  watch: {
    'regions.length': function(val) {
      if (val > 0) {
        this.updateBounds();
      }
    }
  },
  mounted() {
    this.getRegions();
  },
  methods: {
    getRegions() {
      ApiService.get(`experience/meeting/hotel-pickup/regions/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.regions = data.regions;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    updateBounds() {
      this.$refs.map.$mapPromise.then((map) => {
        var bounds = new this.google.maps.LatLngBounds();
        this.regions.forEach((region) => {
          bounds.extend(region.position);
        });
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },
    updateCoordinates(location, index) {
      const data = {
        pid: this.experienceId,
        regionID: this.regions[index].id,
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }

      ApiService.post('experience/meeting/hotel-pickup/regions/set-position.req.php', data)
      .then(() => {
        const newPosition = { lat: location.latLng.lat(), lng: location.latLng.lng() }
        this.regions[index].position = newPosition;
        this.updateBounds();
      })
      .catch(({ response }) => {
        this.getRegions();
        alert(response.data.errorMessage);
      });
    },

    setPlace(place) {
      this.addItemModal.place = place;
    },
    
    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1;
      this.addItemModal.place = null;
      this.addItemModal.radius = 1;
    },

    handleAddItemModalOk() {
      const place = this.addItemModal.place;
      const radius = this.addItemModal.radius * 1000;
      const data = {
        pid: this.experienceId,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
        radius: radius
      }

      ApiService.post('experience/meeting/hotel-pickup/regions/add.req.php', data)
      .then(({ data }) => {
        const newRegion = {
          id: data.regionId,
          position: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
          address: place.formatted_address,
          radius: radius
        }
        this.regions.push(newRegion);
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    removeRegion(index) {
      const regionID = this.regions[index].id;
      const data = {
        regionID,
        pid: this.experienceId
      }

      ApiService.post('experience/meeting/hotel-pickup/regions/remove.req.php', data)
      .then(() => {
        this.regions.splice(index, 1);
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    openEditRegionModal(id) {
      const region = this.regions.filter(function(el) {
        return el.id == id;
      })[0];
      this.editItemModal.selectedRegionId = id;
      this.editItemModal.selectedRegionRadius = region.radius / 1000;
      this.$bvModal.show('modal-edit-hotel-pickup-region');
    },

    handleEditItemModalCancel() {
      this.editItemModal.selectedRegionId = null;
      this.editItemModal.selectedRegionRadius = '';
    },

    handleEditItemModalOk() {
      const regionID = this.editItemModal.selectedRegionId;
      const region = this.regions.filter(function(el) {
        return el.id == regionID;
      })[0];
      const index = this.regions.indexOf(region);
      const radius = this.$refs.editRegionModalRadius.value * 1000;

      const data = {
        regionID,
        radius,
        pid: this.experienceId,
      }

      ApiService.post('experience/meeting/hotel-pickup/regions/set-radius.req.php', data)
      .then(() => {
        this.regions[index].radius = radius
        this.updateBounds();
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 100000;
  border-radius: 8px;
}
.pac-item {
  padding: 8px;
}
</style>