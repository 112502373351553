<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Özet</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <p class="text-body font-size-h6 font-weight-normal" style="width: 80%;"> 
      {{ summary[lang] }}
    </p>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["summary", "lang"]
}
</script>

<style>

</style>