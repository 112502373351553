<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Seyahat programı</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">
        <span v-if="itinerary.destinations.length != 0">Düzenle</span>
        <span v-else>Ekle</span>
      </button>
    </div>

    <div class="row" v-if="itinerary.destinations.length != 0">
      <div class="col-xl-6">
        <div class="d-flex align-items-center">
          <span v-if="itinerary.duration.amount != 0" class="label label-danger text-white font-weight-bold label-pill label-inline orange-bg">{{itinerary.duration.amount}} {{ durationType[lang][itinerary.duration.type] }}</span>
          <div class="timeline timeline-1">
            <div class="timeline-sep bg-light-primary"></div>
            <div class="timeline-item transit-line mb-20">
              <div class="timeline-label"><span class="label label-dark text-white font-weight-bold label-pill label-inline">{{itinerary.start.duration.amount}} {{ durationType[lang][itinerary.start.duration.type] }}</span></div>
              <div class="timeline-badge">
                <i class="flaticon2-pin text-dark icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bolder font-size-h6">
                <span class="mr-3">{{ itinerary.start.title[lang] }}</span>
              </div>
            </div>
            <template v-for="(destination, index) in itinerary.destinations">
              <div class="timeline-item" :class="{'mb-10': destination.stops.length == 0}" :key="`destination_${index}`">
                <div class="timeline-label"></div>
                <div class="timeline-badge">
                    <i class="flaticon2-pin orange-pin icon-lg"></i>
                </div>
                <div class="timeline-content text-body font-weight-bolder font-size-h6">
                  <span class="mr-3">{{ destination.title[lang] }}</span>
                </div>
              </div>
              <div class="timeline-item mt-5 mb-10" v-for="(stop, stopIndex) in destination.stops" :key="`stop_${index}_${stopIndex}`">
                <div class="timeline-label"></div>
                <div class="timeline-badge">
                  <span class="label label-dot orange-bg label-lg"></span>
                </div>
                <div class="timeline-content text-body font-weight-normal font-size-h6">
                  <span class="mr-3">{{ stop.title[lang] }}</span>
                </div>
              </div>
            </template>
            <div class="timeline-item mt-20">
              <div class="timeline-label timeline-label-end"><span class="label label-dark text-white font-weight-bold label-pill label-inline">{{itinerary.end.duration.amount}} {{ durationType[lang][itinerary.end.duration.type] }}</span></div>
              <div class="timeline-badge">
                  <i class="flaticon2-pin text-success icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bolder font-size-h6">
                <span class="mr-3">{{ itinerary.end.title[lang] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6" v-show="mapLoaded">
        <gmap-map
          ref="itineraryMap"
          :options="map.options"
          :center="map.center"
          :zoom="map.zoom"
          style="width:100%;  height: 400px;"
        >
          <template v-for="(destination, index) in itinerary.destinations">
            <gmap-marker
              :key="`marker_${index}`"
              :position="destination.location"
              :clickable="true"
              :draggable="false"
              :animation="google.maps.Animation.DROP"
              @click="openInfoWindowTemplate(destination.title[lang], destination.location)"
            />
            <gmap-marker
              v-for="(stop, index) in destination.stops"
              :key="`marker_stop_${index}`"
              :position="stop.location"
              :clickable="true"
              :draggable="false"
              @click="openInfoWindowTemplate(stop.title[lang], stop.location)"
              :icon="{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#FC441F',
                fillOpacity: 1,
                strokeColor: '#FC441F',
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 7
              }"
            />
          </template>

          <gmap-marker
            v-if="itinerary.start != null"
            :position="itinerary.start.location"
            :clickable="true"
            :draggable="false"
            :animation="google.maps.Animation.DROP"
            @click="openInfoWindowTemplate(`Başlangıç: ${itinerary.start.title[lang]}`, itinerary.start.location)"
          />
          <gmap-marker
            v-if="itinerary.end != null"
            :position="itinerary.end.location"
            :clickable="true"
            :draggable="false"
            :animation="google.maps.Animation.DROP"
            @click="openInfoWindowTemplate(`Bitiş: ${itinerary.end.title[lang]}`, itinerary.end.location)"
          />
          <gmap-info-window
            :options="{ maxWidth: 300, pixelOffset: {width: 0,height: -35} }"
            :position="map.infoWindow.position"
            :opened="map.infoWindow.open"
            @closeclick="map.infoWindow.open=false">
            <div v-html="map.infoWindow.template"></div>
          </gmap-info-window>
        </gmap-map>
      </div>
    </div>
    <div v-else>
      <p class="font-size-h6 font-weight-normal text-body">Seyahat programı, misafirlerin deneyiminizi bulmasına ve rezervasyon yapmasına yardımcı olur.</p>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps';
export default {
  props: ["itinerary", "lang"],
  data() {
    return {
      map: {
        zoom: 10,
        center: { lat: 41.0055066, lng: 39.731024 },
        options:{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false
        },
        infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        }
      },
      durationType: {
        tr: {
          m: 'Dakika',
          h: 'Saat'
        },
        en: {
          m: 'Minutes',
          h: 'Hours'
        }
        
      },
      mapLoaded: false
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi
  },
  mounted() {
    const self = this;
    this.$gmapApiPromiseLazy().then(() => { 
      self.mapLoaded = true;
    });
  },
  watch: {
    mapLoaded(val) {
      if (val) {
        this.updateBounds();
      }
    }
  },
  methods: {
    updateBounds() {
      const self = this;
      this.$refs.itineraryMap.$mapPromise.then((map) => {
        var bounds = new this.google.maps.LatLngBounds();
        this.itinerary.destinations.forEach((destination) => {
          if (destination.stops.length > 0) {
            destination.stops.forEach((stop) => {
              bounds.extend(stop.location);
            })
          }
          bounds.extend(destination.location);
        });
        if (self.itinerary.start != null) {
          bounds.extend(self.itinerary.start.location);
        }
        if (self.itinerary.end != null) {
          bounds.extend(self.itinerary.end.location);
        }
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },
    openInfoWindowTemplate (template, location) {
      this.map.infoWindow.template = `<span class="font-weight-bold">${template}</span>`;
      this.map.infoWindow.position = location;
      this.map.infoWindow.open = true
    }
  }
}
</script>

<style scoped>
.option-meeting-type {
  flex: 1 1 33%;
}
.orange-pin {
  color: #FC441F;
}
.orange-bg {
  background-color: #FC441F !important;
}
.timeline-sep {
  left: 13px !important;
}
.transit-line {
  position: relative;
}
.timeline-item {
  position: relative;
  margin-left: 0;
}
.timeline-label {
  top: 50px;
  position: absolute;
  left: -10px;
}
.timeline-label.timeline-label-end {
  top: -40px;
}
</style>