<template>
  <transition name="section" leave-active-class="hidden">
    <div class="overlay-modal row bg-light">
      <div class="offset-xl-3 col-xl-6 pt-10">
        <div class="card card-custom card-fit">
          <div class="card-header">
            <div class="card-title">
            </div>
            <div class="card-toolbar">
              <button class="btn btn-sm btn-light font-weight-bold" @click="close">
                <i class="flaticon2-cross icon-xs"></i>
                Kapat
              </button>
            </div>
          </div>
          <div class="card-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
  
</template>

<script>
export default {
  name:"OverlayModalBase",
  methods: {
    close() {
      this.hidden = true;
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.overlay-modal {
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: slideUp .8s ease forwards;
  overflow: scroll;
}
.overlay-modal.hidden {
  animation: slideDown .8s ease forwards;
}
.card {
  height: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@keyframes slideUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

</style>