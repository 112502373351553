<template>
  <div class="card card-custom card-fit experience-content">
    <div class="card-header" v-if="false">
      <div class="card-title">
        <h3 class="card-label">Ayarlar</h3>
      </div>
    </div>
    <div class="card-body" v-if="settings != null">
      <Status :status="settings.status" @edit="openEditor('status')" />
      <HighlightPoint
        :data="settings.highlightOrder"
        @edit="openEditor('highlightPoint')"
      />
      <SalesChannels
        :data="settings.salesChannels"
        @edit="openEditor('salesChannels')"
      />
      <MaxParticipant
        :data="settings.maxParticipant"
        @edit="openEditor('maxParticipant')"
      />
      <Participant
        :participants="settings.participants"
        @edit="openEditor('participant')"
      />
      <Category :data="settings.category" @edit="openEditor('category')" />
      <SeoURL
        :experience-id="experienceId"
        :url="settings.url"
        @edit="openEditor('url')"
      />
      <Ribbon :data="settings.ribbon" @edit="openEditor('ribbon')" />
      <Remove :experience-id="experienceId" />
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import SalesChannels from "@/view/pages/experience/components/settings/SalesChannels";
import MaxParticipant from "@/view/pages/experience/components/settings/MaxParticipant";
import Participant from "@/view/pages/experience/components/settings/Participant";
import HighlightPoint from "@/view/pages/experience/components/settings/HighlightPoint";
import Status from "@/view/pages/experience/components/settings/Status";
import Remove from "@/view/pages/experience/components/settings/Remove";
import Category from "@/view/pages/experience/components/settings/Category";
import SeoURL from "@/view/pages/experience/components/settings/SeoURL";
import Ribbon from "@/view/pages/experience/components/settings/Ribbon";

export default {
  props: ["experienceId"],
  data() {
    return {
      settings: null,
    }
  },
  components: {
    SalesChannels,
    MaxParticipant,
    Participant,
    HighlightPoint,
    Status,
    Category,
    Ribbon,
    SeoURL,
    Remove
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      ApiService.get(`experience/settings/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.settings = data.settings;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    openEditor(editor) {
      this.$emit('edit', editor);
    },
  }
}
</script>

<style>
</style>