<template>
  <div>
    <div class="d-flex flex-column align-items-center justify-content-center" v-if="!isValid">
      <img src="media/svg/illustrations/e-commerce.svg" style="width:60%"/>
      <label class="d-block font-weight-bold font-size-h6 text-muted text-center py-5">Son 3 ay içerisinde herhangi bir satış gerçekleşmedi.</label> 
    </div>
    <div v-else>
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="line"
        v-if="!error.status"
      ></apexchart>
      
      <div class="d-flex flex-column align-items-center justify-content-center" v-if="error.status">
        <img src="media/svg/illustrations/failure.svg" style="width:50%"/>
        <label class="d-block font-weight-bold font-size-h6 text-danger text-center py-5">{{ error.message }}</label> 
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';
import moment from 'moment';

export default {
  props: ["experienceId"],
  data() {
    return {
      isValid: true,
      chartOptions: {},
      series: [],
      xAxis: [],
      error: {
        status: false,
        message: ''
      },
    } 
  },
  methods: {
    getData() {
      ApiService.get(`experience/chart/last-3-month-sales/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        const numberOfBooking = data.quantity.reduce(function(acc, val) { return acc + val; }, 0);
        if (numberOfBooking > 0) {
          this.isValid = true;
          this.series = [
            {
              name: "Rezervasyon",
              data: data.quantity
            },
            {
              name: "Yolcu",
              data: data.pax
            }
          ];
        } else {
          this.isValid = false;
        }
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    moment.locale("tr");
    this.getData();
    this.chartOptions =  {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["15%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: true
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          moment().subtract(2, 'months').format('MMMM'),
          moment().subtract(1, 'months').format('MMMM'),
          moment().format('MMMM')
        ],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        }
      ],
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: [
          {
            formatter: function(val) {
              return val + " Adet";
            }
          },
          {
            formatter: function(val) {
              return val + " Pax";
            }
          }
        ] 
      },
      colors: [
        this.layoutConfig("colors.theme.base.success"),
        this.layoutConfig("colors.gray.gray-300")
      ],
      grid: {
        borderColor: this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    }
  }
}
</script>

<style>

</style>