<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Etiket</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">
        <span v-if="data == null">Ekle</span>
        <span v-else>Düzenle</span>
      </button>
    </div>

     <div v-if="data != null">
      <div class="form-group">
        <span class="label label-pill label-inline label-xl text-white font-size-lg text-uppercase font-weight-bold" :style="`background: ${data.color}`">{{ data.title.tr }}</span>
      </div>  
    </div>
    <div v-else>
      <p class="font-size-h6 font-weight-normal text-body">Etiketler, misafirlerin deneyiminizi bulmasına yardımcı olur.</p>
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style>
</style>