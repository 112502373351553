<template>
  <div class="card card-custom gutter-b card-stretch">
    <div class="card-header h-auto border-0">
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Ülkelere Göre Rezervasyonlar</span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex flex-column align-items-center justify-content-center" v-if="error.status">
        <img src="media/svg/illustrations/failure.svg" style="width:50%"/>
        <label class="d-block font-weight-bold font-size-h6 text-danger text-center py-5">{{ error.message }}</label> 
      </div>
      <div v-else>
        <div class="d-flex flex-column align-items-center justify-content-center" v-if="isEmpty">
          <img src="media/svg/illustrations/e-commerce.svg" style="width:60%"/>
          <label class="d-block font-weight-bold font-size-h6 text-muted text-center py-5">Henüz herhangi bir rezervasyon yapılmadı.</label> 
        </div>
        <div class="table-responsive" v-if="!isEmpty">
          <table class="table table-borderless mb-0">
            <tbody>
              <tr v-for="(item, index) in sales" :key="index">
                <td class="align-middle px-0 py-5">
                  <!--
                  <div class="symbol symbol-25 symbol-light-success">
                    <div class="symbol-label" :style="`background-image: url('media/svg/flags-ISO/${item.countryCode}.svg')`"></div>
                  </div>
                  -->
                  <Flag :code="item.countryCode" size="M" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true" gradient="real-linear"/>
                </td>
                <td class="align-middle">
                  <div class="font-size-lg font-weight-bolder text-dark-75 mb-1">{{ item.country }}</div>
                </td>
                <td class="text-right align-middle">
                  <div class="font-size-lg font-weight-bold text-dark-50">{{ item.pax }} Pax</div>
                </td>
                <td class="text-right align-middle">
                  <div class="font-size-lg font-weight-bold text-dark-75">{{ item.numberOfSale }} Adet</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data() {
    return {
      isEmpty: false,
      limit: 6,
      sales: [],
      error: {
        status: false,
        message: ''
      },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get(`experience/overview/sales-by-country/get.req.php?id=${this.experienceId}&limit=${this.limit}`)
      .then(({ data }) => {
        if (data.sales.length > 0) {
          this.isEmpty = false;
          this.sales = data.sales;
        } else {
          this.isEmpty = true;
        }
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  }
}
</script>

<style>

</style>