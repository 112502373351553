<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Yayınlanma durumunu düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizin erişilebilirliğini değiştirin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-for="(item, index) in statusOptions" :key="index">
      <label class="option selectable option-status mr-4" :class="{'selected': status == item.value}"> 
        <span class="option-control">
          <span class="radio radio-dark">
            <input
              type="radio"
              name="status_option"
              :value="item.value"
              v-model="status"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              {{ item.name }}
            </span>
          </span>
        </span>
      </label>
    </div>


    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setStatus"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data() {
    return {
      statusOptions: [
        {
          name: 'Yayında',
          value: 1
        },
        {
          name: 'Arşivde',
          value: 2
        },
        {
          name: 'Taslaklarda',
          value: 3
        },
        {
          name: 'Satışa kapalı',
          value: 7
        }
      ],
      status: 0,
      error: {
        status: false,
        message: '',
      },
      isSaving: false
    }
  },
  computed: {
    isSaveButtonDisabled() {
      const res = this.statusOptions.filter((el) => {
        return el.value == this.status
      });
      
      return res.length == 0;
    }
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus() {
      ApiService.get(`experience/status/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.status = data.experienceStatus;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    setStatus() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        status: this.status
      }
      ApiService.post('experience/status/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
  }
}
</script>

<style scoped>
.option-status {
  flex: 1 1 50%;
}
</style>