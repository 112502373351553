<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Buluşma seçenekleri</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="hotel mb-15" v-if="data.hotel.status">
        <label class="font-weight-bolder text-body font-size-h5">Otel</label>
        <div class="d-flex align-items-center flex-wrap" v-for="(item, index) in data.hotel.regions" :key="index">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <span class="font-weight-normal text-body font-size-h6 mb-1">{{ item.address }}</span>
          </div>
        </div>
        <div class="form-group">
          <span class="text-body font-size-h6 font-weight-bolder">Servis Ücreti: 
            <span class="font-weight-normal" v-if="data.hotel.serviceFee.status">{{ data.hotel.serviceFee.amount }} TL / {{ serviceFeeTypeText(data.hotel.serviceFee.type) }}</span>
            <span class="font-weight-normal" v-else>Yok</span>
          </span>
        </div>  
      </div>
      <div class="airport mb-15" v-if="data.airport.status">
        <label class="font-weight-bolder text-body font-size-h5">Havalimanı</label>
        <div class="d-flex align-items-center flex-wrap" v-for="(item, index) in data.airport.airports" :key="index">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <span class="font-weight-normal text-body font-size-h6 mb-1">{{ item.name }}</span>
          </div>
        </div>
        <div class="form-group">
          <span class="text-body font-size-h6 font-weight-bolder">Servis Ücreti: 
            <span class="font-weight-normal" v-if="data.airport.serviceFee.status">{{ data.airport.serviceFee.amount }} TL</span>
            <span class="font-weight-normal" v-else>Yok</span>
          </span>
        </div>  
      </div>
      <div class="point mb-15" v-if="data.point.status">
        <label class="font-weight-bolder text-body font-size-h5">Buluşma Noktası</label>
        <div class="d-flex align-items-center flex-wrap" v-for="(item, index) in data.point.points" :key="index">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <span class="font-weight-normal text-body font-size-h6 mb-1">{{ item.title[lang] }}</span>
          </div>
        </div>
      </div>

      <div class="point" v-if="dropOff.status">
        <label class="font-weight-bolder text-body font-size-h5">Varış</label>
        <div class="d-flex align-items-center flex-wrap" v-for="(item, index) in dropOff.items" :key="index">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <span class="font-weight-normal text-body font-size-h6 mb-1">{{ item.name[lang] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data", "dropOff", "lang"],
  methods: {
    serviceFeeTypeText(type) {
      if (type == 1) {
        return 'kişi başı';
      } else if (type == 2) {
        return 'araç başı';
      } else { return ''; }
    },
  }
}
</script>

<style>

</style>