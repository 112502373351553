<template>
  <div>
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-row align-items-center justify-content-between mb-10">
        <div class="d-flex flex-column">
          <h1 class="font-weight-bolder text-dark my-2 mr-5">
            {{ profile.name }}
          </h1>
          <div class="my-2">
            <label class="label label-inline label-outline-secondary label-lg font-size-lg text-body font-weight-bolder mr-6">
              <span class="label label-dot label-lg mr-2" :class="statusLabelColor"></span>
              {{ statusLabelText }}
            </label>
            <label class="label label-inline label-outline-secondary label-lg font-size-lg text-body font-weight-bolder mr-6">
              <i class="fa fa-star mr-2 text-warning icon-nm"></i>{{ profile.rating }}
            </label>
            <a :href="viewOnSiteHref" target="_blank" class="font-size-lg text-danger font-weight-bold">
              <span>View on Zenofon</span>
              <i class="la la-external-link-alt text-danger icon-lg pl-1"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div
          class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
          id="kt_profile_aside"
        >
          <div class="card card-custom mb-10">
            <div class="card-body pt-8">
              
              <div class="d-flex flex-column flex-center">
                <div class="bgi-no-repeat bgi-position-center bgi-size-cover rounded min-h-150px w-100" :style="{ backgroundImage: `url(${profile.coverPhoto})` }">
                </div>
              </div>

              <!--begin::Nav-->
              <div class="navi navi-bold navi-hover navi-active navi-link-rounded mt-9" role="tablist">
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4 active"
                    @click="setActiveTab"
                    href="javascript:;"
                    data-tab="0"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg src="media/svg/icons/Design/Layers.svg" />
                      </span>
                    </span>
                    <span class="navi-text font-size-h6">Genel Bakış</span>
                  </a>
                </div>
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4"
                    @click="setActiveTab"
                    href="javascript:;"
                    data-tab="1"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg src="media/svg/icons/General/Star.svg" />
                      </span>
                    </span>
                    <span class="navi-text font-size-h6">Değerlendirmeler</span>
                    <span class="navi-label" v-if="profile.numberOfPendingReviews > 0">
                      <span class="label label-light-danger label-rounded font-weight-bold">{{ profile.numberOfPendingReviews }}</span>
                    </span>
                  </a>
                </div>
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4"
                    @click="setActiveTab"
                    href="javascript:;"
                    data-tab="2"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                      </span>
                    </span>
                    <span class="navi-text font-size-h6">Ürün İçeriği</span>
                  </a>
                </div>
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4"
                    @click="setActiveTab"
                    href="javascript:;"
                    data-tab="3"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg
                          src="media/svg/icons/General/Settings-2.svg"
                        />
                      </span>
                    </span>
                    <span class="navi-text font-size-h6">Ayarlar</span>
                  </a>
                </div>
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4"
                    @click="calendarState = 'show'"
                    href="javascript:;"
                    data-tab="3"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-grid.svg"
                        />
                      </span>
                    </span>
                    <span class="navi-text font-size-h6">Müsaitlik Takvimi</span>
                  </a>
                </div>
              </div>
              <!--end::Nav-->

            </div>
          </div>
          <div class="card card-custom ">
            <!--begin::Card Body-->
            <div class="card-body">
              <h3 class="font-weight-bold font-size-h4 text-dark-75 mb-6">Genel Bilgiler</h3>
              <!--begin::Info-->
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Tema</span>
                <span class="text-dark flex-root font-weight-bold">{{ themeText }}</span>
              </div>
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Hizmet Tipi</span>
                <span class="text-dark flex-root font-weight-bold">{{ serviceTypeText }}</span>
              </div>
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Hareket Yeri</span>
                <span class="text-dark flex-root font-weight-bold">{{ profile.departurePoint }}</span>
              </div>
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Süre</span>
                <span class="text-dark flex-root font-weight-bold">{{ profile.duration }}</span>
              </div>
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Max Katılımcı</span>
                <span class="text-dark flex-root font-weight-bold">{{ maxParticipantText }}</span>
              </div>
              <div class="d-flex mb-3">
                <span class="text-dark-50 flex-root font-weight-bold">Listelenme</span>
                <span class="text-dark flex-root font-weight-bold">{{ listingOrderText }}</span>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Card Body-->
          </div>
        </div>

        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">
          <b-tabs class="hide-tabs" v-model="tabIndex" lazy>
            <b-tab active>
              <ExperienceOverview :experience-id="experienceId"/>
            </b-tab>
            <b-tab>
              <ExperienceReviews :experience-id="experienceId" :number-of-pending="profile.numberOfPendingReviews" @updated="update"/>
            </b-tab>
            <b-tab>
              <ExperienceContent :experience-id="experienceId" @updated="update"></ExperienceContent>
            </b-tab>
            <b-tab>
              <ExperienceSettings :experience-id="experienceId" @updated="update"></ExperienceSettings>
            </b-tab>

          </b-tabs>
        </div>
      </div>

      <FullScreenOverlayModalBase @close="calendarState = 'hidden'"  v-if="calendarState === 'show'">
        <ExperienceCalendar :experience-id="experienceId" />
      </FullScreenOverlayModalBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from '@/core/services/api.service';

import ExperienceContent from "@/view/pages/experience/components/ExperienceContent";
import ExperienceSettings from "@/view/pages/experience/components/ExperienceSettings";
import ExperienceReviews from "@/view/pages/experience/components/ExperienceReviews";
import ExperienceOverview from "@/view/pages/experience/components/ExperienceOverview";
import ExperienceCalendar from "@/view/pages/experience/components/ExperienceCalendar";
import FullScreenOverlayModalBase from "@/view/pages/experience/base_ui/FullScreenOverlayModalBase";

export default {
  name: "ExperienceProfile",
  components: {
    ExperienceContent,
    ExperienceSettings,
    ExperienceReviews,
    ExperienceOverview,
    ExperienceCalendar,
    FullScreenOverlayModalBase
  },
  data () {
    return {
      profile: null,
      tabIndex: -1,
      experienceId: this.$route.params.id,
      error: {
        status: false,
        message: 'Beklenmedik bir hatayla karşılaştık. Lütfen daya sonra tekrar deneyin.'
      },
      calendarState: 'hidden'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Experience" }]);
    this.getProfile();
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    statusLabelColor() {
      return 'label-'+this.experienceConfig(`status.${this.profile.status}.color`);
    },
    statusLabelText() {
      return this.experienceConfig(`status.${this.profile.status}.text`);
    },
    themeText() {
      return this.experienceConfig(`theme.${this.profile.theme}.tr`);
    },
    serviceTypeText() {
      return this.experienceConfig(`serviceType.${this.profile.serviceType}.tr`);
    },
    maxParticipantText() {
      return this.profile.maxParticipant + ' Pax';
    },
    listingOrderText() {
      return this.profile.order + '. Sıra';
    },
    viewOnSiteHref() {
      return `https://zenofontours.com/product-t${this.experienceId}`;
    }
  },
  watch: {
    'profile.status': function (newVal) {
      if (newVal === 4) {
        this.$router.push({ name: "create-experience", params: { id: this.experienceId } });
      } else if (newVal === 5) {
        this.$router.push({ name: "create-experience", params: { id: this.experienceId } })
      } else if (newVal === 6) {
        this.$router.push({ name: "404" });
      } else if (newVal === 0) {
        this.$router.push({ name: "404" });
      } 
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },

    getProfile() {
      ApiService.get(`experience/profile/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.profile = data.profile;
        this.error.status = false;
      })
      .catch(() => {
        this.error.status = true;
      });
    },
    
    update() {
      this.getProfile();
    }
  }
};
</script>

<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
.hide-tabs .nav-tabs {
  display: none !important;
}
</style>