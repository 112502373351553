<template>
  <div class="card card-custom card-stretch">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Rezervasyonlar
          <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ items.length }} adet rezervasyon bulunmakta</span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-10">
        <div class="row align-items-center">
          <div class="col-md-4 my-2 my-md-0">
            <DateRangePicker :initial-date="{ start: filterDateRange.start, end: filterDateRange.end }" :max-date="maxDate" @change="dateChanged"/>
          </div>
          <div class="col-md-4 my-2 my-md-0">
            <div class="input-icon">
              <input v-model="filter" type="text" class="form-control form-control-solid" placeholder="Rezervasyon No" id="filterInput">
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <b-table
        class="table table-head-custom table-vertical-center"
        thead-class="mb-8"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir deneyim bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-20">
            <b-spinner class="align-middle mr-4"></b-spinner>
            <strong class="font-size-h6">Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(reservationNumber)="data">
          <div class="pl-0 py-4">
            <a href="javascript:;" class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">ZNF-{{ data.item.reservationNumber }}</a>
            <span class="text-muted font-weight-bold d-block">{{ data.item.reservationOwner }}</span>
          </div>
        </template>
        <template #cell(dateTime)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
            {{ renderDateTime(data.item.departureDateTime, data.item.endDateTime) }}
          </span>
        </template>
        <template #cell(pax)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ data.item.pax }} Pax</span>
        </template>
        <template #cell(price)="data">
          <span class="text-dark-75 font-weight-bolder font-size-lg pr-1">{{ data.item.price.amount }}</span>
          <span class="text-dark-75 font-weight-bold font-size-lg">{{ data.item.price.currency }}</span>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-success font-weight-bold label-inline" v-if="data.item.status == 1">Onaylandı</span>
          <span class="label label-lg label-danger font-weight-bold label-inline" v-if="data.item.status == 0">İptal Edildi</span>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'reservation-details', params: { id: data.item.id, reservationNumber: data.item.reservationNumber }}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
              </span>
            </a>
          </router-link>
        </template>
        
      </b-table>
      <b-col sm="7" md="6" class="my-1" v-if="items.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          size="md"
        >
        </b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import DateRangePicker from "@/view/content/datepicker/DateRangePicker";

export default {
  props: ["experienceId"],
  components: {
    DateRangePicker,
  },
  data () {
    return {
      isBusy: false,
      items: [],
      fields: [
        { key: 'reservationNumber', label: 'REZERVASYON NO', sortable: false },
        { key: 'dateTime', label: 'HAREKET TARİHİ', sortable: false },
        { key: 'pax', label: 'PAX', sortable: false, class: 'text-right' },
        { key: 'price', label: 'FİYAT', sortable: false, class: 'text-right' },
        { key: 'status', label: 'DURUM', sortable: false, class: 'text-right' },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      filterDateRange: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    maxDate() {
      return moment().format('YYYY-MM-DD');
    }
  },
  mounted() {
    moment.locale('tr');
    this.getReservations();
  },
  methods: {
    getReservations() {
      this.isBusy = true;
      
      if (this.filterDateRange.start !== null) {
        var startParam = 'start=' + this.filterDateRange.start;
      }
      if (this.filterDateRange.end !== null) {
        var endParam = 'end=' + this.filterDateRange.end;
      }

      ApiService.get(`experience/reservation/get.req.php?id=${this.experienceId}&${startParam}&${endParam}`)
      .then(({ data }) => {
        this.items = data.reservations;
        this.totalRows = this.items.length
        setTimeout(() => {
          this.isBusy = false; 
        }, 500);
      })
      .catch(() => {
        this.items = [];
        setTimeout(() => {
          this.isBusy = false; 
        }, 500);
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    dateChanged(dateRange) {
      this.filterDateRange.start = dateRange.start;
      this.filterDateRange.end = dateRange.end;
      this.getReservations();
    },
    renderDateTime(departureDate, endDate) {
      let departure = moment(departureDate, 'YYYY-MM-DD HH:mm:ss');
      let end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
      
      if (moment(departure.format('YYYY-MM-DD')).isSame(end.format('YYYY-MM-DD'))) {
        return departure.format('D MMM, HH:mm');
      } else {
        if (moment(departure.format('YYYY-MM')).isSame(end.format('YYYY-MM'))) {
          return departure.format('D-') + end.format('D MMM');
        } else {
          return departure.format('D MMM - ') + end.format('D MMM');
        }
      }
    }
  }
}
</script>