<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Kategori</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">
        <span v-if="data.mainCategory == null">Ekle</span>
        <span v-else>Düzenle</span>
      </button>
    </div>

     <div v-if="data.mainCategory != null">
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Ana Kategori: <span class="font-weight-normal">{{ data.mainCategory.tr }}</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Alt kategoriler: <span class="font-weight-normal pr-2" v-for="(item, index) in data.subCategories" :key="index">{{ item.title.tr }}<span v-if="index+1 != data.subCategories.length">,</span></span></span>
      </div>  
    </div>
    <div v-else>
      <p class="font-size-h6 font-weight-normal text-body">Kategoriler, misafirlerin deneyiminizi bulmasına ve rezervasyon yapmasına yardımcı olur.</p>
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style>
</style>