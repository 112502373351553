<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Tam açıklama</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div style="width: 80%">
      <p class="text-body font-size-h6 font-weight-normal" :class="{'collapsed': !readMore}" v-html="fullDesc[lang]"></p>
      <a href="javascript:;" class="font-weight-bold text-primary font-size-lg" @click="readMore = true" v-if="!readMore">Daha fazla oku</a>
      <a href="javascript:;" class="font-weight-bold text-primary font-size-lg" @click="readMore = false" v-else>Daha az oku</a>
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["fullDesc", "lang"],
  data() {
    return {
      readMore: false
    }
  }
}
</script>

<style scoped>
.collapsed {
  height: 203.04px; 
  overflow:hidden;
}
</style>