<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      SEO uyumlu URL'yi düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Arama motorlarına ve kullanıcılara etkinliği en iyi anlatan url bilgisini
      girin.
    </p>

    <div
      class="alert alert-warning bg-light-warning my-8"
      role="alert"
      v-if="error.status"
    >
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">Türkçe</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">https://www.zenofontours.com/</span>
        </div>
        <input
          placeholder="URL uzantısı"
          type="text"
          class="form-control"
          v-model.number="url.tr"
          ref="inputTR"
        />
        <div class="input-group-append">
          <span class="input-group-text">-t{{ experienceId }}</span>
        </div>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">İngilizce</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">https://www.zenofontours.com/</span>
        </div>
        <input
          placeholder="URL uzantısı"
          type="text"
          class="form-control"
          v-model.number="url.en"
          ref="inputEN"
        />
        <div class="input-group-append">
          <span class="input-group-text">-t{{ experienceId }}</span>
        </div>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center"
          :disabled="isSaveButtonDisabled || isSaving"
          @click="set"
        >
          <div class="spinner spinner-white pr-10" v-if="isSaving"></div>
          <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: {
    experienceId: Number
  },
  data() {
    return {
      url: {
        tr: '',
        en: ''
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen url girin.',
      },
      isSaving: false,
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.url.tr.length === 0 || this.url.en.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/seo-url/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.url = data.url;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    set() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        url: JSON.stringify(this.url)
      }

      ApiService.post('experience/seo-url/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
};
</script>