<template>
  <div>
    <Content ref="contentComponent" :experience-id="experienceId" @edit="openOverlay"/>
    <Overlay :experience-id="experienceId" :product-content="productContent" @close="closeOverlayModal" v-if="productContent.selectedSection != null"/>
  </div>
</template>

<script>
import Content from "@/view/pages/experience/components/content/Content";
import Overlay from "@/view/pages/experience/components/content/Overlay";

export default {
  props: ["experienceId"],
  components: {
    Content,
    Overlay
  },
  data() {
    return {
      productContent: {
        selectedSection: null
      }
    }
  },
  methods: {
    closeOverlayModal() {
      this.productContent.selectedSection = null;
      this.$refs.contentComponent.getContent();
      this.$emit('updated');
    },
    openOverlay(editor) {
      this.productContent.selectedSection = editor;
    }
  }
}
</script>

<style scoped>
.experience-content {
  line-height: 1.8;
}
</style>