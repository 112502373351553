<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Katılımcıları düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyime katılabilecek yolcu tiplerini seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="d-flex justify-content-center min-h-100px" v-if="isSaving">
      <div class="spinner spinner-dark"></div>
    </div>

    <div v-else>
      <div
        class="form-group"
        v-for="(participant, index) in selectedParticipants"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-control mr-4" style="width: auto;">
            <div class="symbol">
              <span class="symbol-label">
                <inline-svg :src="participantDetails[participant.id].avatar" />
              </span>
            </div>
          </span>
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                {{ participantDetails[participant.id].title }}
              </div>
              <div class="option-focus">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  v-if="participant.id != 'passenger' "
                  @click="openEditParticipantModal(participant.id)"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 mw-80 pt-0">
              {{ participant.age }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <!--Begin: Edit item modal-->
    <b-modal id="modal-edit-participant-item" ref="modal-edit-participant-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.selectedItemTitle }}</label>
            <h5>Yaş aralığı girin</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="Geçerli yaş aralığı"
          ref="editParticipantModalAge"
          v-model.trim="editItemModal.selectedItemAge"
        >
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok"
            :disabled="editItemModal.selectedItemAge == ''"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->


  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorParticipant',
  props: ['experienceId'],
  data() {
    return {
      selectedParticipants: [],
      participantDetails: {
        passenger: {
          title: "Yolcu",
          avatar: "/media/svg/participants/passenger.svg"
        },
        elderly: {
          title: "Yaşlı",
          avatar: "/media/svg/participants/elderly.svg"
        },
        adult: {
          title: "Yetişkin",
          avatar: "/media/svg/participants/adult.svg"
        },
        youth: {
          title: "Genç",
          avatar: "/media/svg/participants/youth.svg"
        },
        student: {
          title: "Öğrenci",
          avatar: "/media/svg/participants/student.svg"
        },
        child: {
          title: "Çocuk",
          avatar: "/media/svg/participants/child.svg"
        },
        child_2: {
          title: "Çocuk 2",
          avatar: "/media/svg/participants/child_2.svg"
        },
        infant: {
          title: "Bebek",
          avatar: "/media/svg/participants/infant.svg"
        },
      },
      editItemModal: {
        selectedItemId: null,
        selectedItemTitle: '',
        selectedItemAge: ''
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen en az bir adet katılımcı ekleyin.',
      },
      isSaving: false
    };
  },
  mounted() {
    this.getParticipants();
  },
  methods: {
    getParticipants() {
      ApiService.get(`experience/participant/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedParticipants = data.selectedParticipants;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setParticipants(data) {
      this.isSaving = true;

      ApiService.post('experience/participant/set.req.php', data)
      .then(() => {
        this.getParticipants();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    openEditParticipantModal(id) {
      const participant = this.selectedParticipants.filter(function(el) {
        return el.id == id;
      })[0];

      this.editItemModal.selectedItemId = id;
      this.editItemModal.selectedItemTitle = this.participantDetails[id].title;
      this.editItemModal.selectedItemAge = participant.age;
      this.$bvModal.show('modal-edit-participant-item');
    },

    handleEditItemModalOk() {
      const participant = this.editItemModal.selectedItemId;

      const data = {
        id: this.experienceId,
        participant: participant,
        age: this.$refs.editParticipantModalAge.value
      };

      this.setParticipants(data);
    },

    handleEditItemModalCancel() {
      this.editItemModal.selectedItemId = null;
      this.editItemModal.selectedItemAge = '';
    }
  },
};
</script>

<style scoped>
.option {
  cursor: initial;
}
.option.selectable {
  cursor: pointer;
}
.option-sml {
  flex: 0 0 25%;
}
</style>
