<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Başlığı düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Kısa, açıklayıcı ve heyecan verici olmasını sağlayın.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
      <div class="input-group input-group-lg input-group-solid">
        <input
          type="text"
          class="form-control font-size-lg"
          :maxlength="maxTitleLength"
          v-model.trim="title.tr"
          ref="inputTitleTR"
        />
        <div class="input-group-append">
          <span
            class="input-group-text font-weight-bold"
            :class="[remainingCharsTR > 0 ? 'text-success' : 'text-dark']"
            >{{ remainingCharsTR }}</span
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
      <div class="input-group input-group-lg input-group-solid">
        <input
          type="text"
          class="form-control font-size-lg"
          :maxlength="maxTitleLength"
          v-model.trim="title.en"
          ref="inputTitleEN"
        />
        <div class="input-group-append">
          <span
            class="input-group-text font-weight-bold"
            :class="[remainingCharsEN > 0 ? 'text-success' : 'text-dark']"
            >{{ remainingCharsEN }}</span
          >
        </div>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setTitle"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorTitle',
  props: ['experienceId'],
  data() {
    return {
      title: {
        tr: '',
        en: '',
      },
      error: {
        status: false,
        message: 'Lütfen deneyiminize bir başlık ekleyin.',
      },
      isSaving: false,
      maxTitleLength: 60,
    };
  },
  computed: {
    remainingCharsTR() {
      return this.maxTitleLength - this.title.tr.length;
    },
    remainingCharsEN() {
      return this.maxTitleLength - this.title.en.length;
    },
    isSaveButtonDisabled() {
      if (this.title.tr.length == 0 || this.title.en.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getTitle();
  },
  methods: {
    getTitle() {
      ApiService.get(`experience/title/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.title.tr = data.title.tr;
        this.title.en = data.title.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setTitle() {
      this.isSaving = true;
      const data = JSON.stringify({
        id: this.experienceId,
        title: JSON.stringify(this.title)
      });

      ApiService.post('experience/title/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
};
</script>

<style scoped>
.form-group input[type='text'] {
  height: 60px;
}
</style>
