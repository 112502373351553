<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Aktif satış kanallarını düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizin hangi satış kanallarında erişilebilir olmasını istiyorsunuz?
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="d-flex flex-row align-items center">
      <label class="option selectable option-sales-channel mr-4" :class="{ selected: b2cIn }"> 
        <span class="option-control">
          <span class="checkbox checkbox-dark checkbox-square">
            <input
              type="checkbox"
              name="sales_channel_option"
              v-model="b2cIn"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              İç Pazar
            </span>
          </span>
        </span>
      </label>
      <label class="option selectable option-sales-channel mr-4" :class="{ selected: b2cOut }">
        <span class="option-control">
          <span class="checkbox checkbox-dark checkbox-square">
            <input
              type="checkbox"
              name="sales_channel_option"
              v-model="b2cOut"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              Dış Pazar
            </span>
          </span>
        </span>
      </label>
    </div>
    

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaveButtonDisabled || isSaving"
          @click="setSalesChannels"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["experienceId"],
  data() {
    return {
      b2cIn: false,
      b2cOut: false,
      error: {
        status: false,
        message: '',
      },
      isSaving: false
    }
  },
  computed: {
    isSaveButtonDisabled() {
      if (!this.b2cIn && !this.b2cOut) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getSalesChannels();
  },
  methods: {
    getSalesChannels() {
      ApiService.get(`experience/sales-channels/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.b2cIn = data.B2CIn == 1;
        this.b2cOut = data.B2COut == 1;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    setSalesChannels() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        b2cIn: this.b2cIn ? 1 : 0,
        b2cOut: this.b2cOut ? 1 : 0,
      }
      ApiService.post('experience/sales-channels/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  }
}
</script>

<style scoped>
.option-sales-channel {
  flex: 1 1 50%;
}
</style>