<template>
  <div class="card card-custom card-fit experience-content">
    <div class="card-header justify-content-center">
      <div class="card-toolbar">
        <b-form-radio-group
          :options="languageOptions"
          v-model="selectedLanguage"
          buttons
          button-variant="outline-secondary font-weight-bold"
          name="radios-btn-language"
        />
      </div>
    </div>
    <div class="card-body" v-if="content != null">
      <Photos :data="content.photos" @edit="openEditor('photos')"/>
      <Setup :data="content.setup" :lang="selectedLanguage" @edit="openEditor('title')"/>
      <Price :data="content.price" :lang="selectedLanguage" @edit="openEditor('price')"/>
      <Duration :data="content.duration" :lang="selectedLanguage" @edit="openEditor('duration')"/>
      <Provide :provides="content.provides" :lang="selectedLanguage" @edit="openEditor('provide')"/>
      <Itinerary :itinerary="content.itinerary" :lang="selectedLanguage" @edit="openEditor('itinerary')"/>
      <Highlight :highlights="content.highlights" :lang="selectedLanguage" @edit="openEditor('highlight')"/>
      <Summary :summary="content.summary" :lang="selectedLanguage" @edit="openEditor('summary')"/>
      <FullDescription :full-desc="content.fullDescription" :lang="selectedLanguage" @edit="openEditor('fullDesc')"/>
      <Include :includes="content.includes" :lang="selectedLanguage" @edit="openEditor('include')"/>
      <Exclude :excludes="content.excludes" :lang="selectedLanguage" @edit="openEditor('exclude')"/>
      <Meeting :data="content.meeting" :lang="selectedLanguage" :drop-off="content.dropOff" @edit="openEditor('meeting')"/>
      <Important :importants="content.importants" :lang="selectedLanguage" @edit="openEditor('important')"/>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Photos from "@/view/pages/experience/components/content/Photos";
import Setup from "@/view/pages/experience/components/content/Setup";
import Provide from "@/view/pages/experience/components/content/Provide";
import Highlight from "@/view/pages/experience/components/content/Highlight";
import Summary from "@/view/pages/experience/components/content/Summary";
import FullDescription from "@/view/pages/experience/components/content/FullDescription";
import Include from "@/view/pages/experience/components/content/Include";
import Exclude from "@/view/pages/experience/components/content/Exclude";
import Important from "@/view/pages/experience/components/content/Important";
import Duration from "@/view/pages/experience/components/content/Duration";
import Price from "@/view/pages/experience/components/content/Price";
import Itinerary from "@/view/pages/experience/components/content/Itinerary";
import Meeting from "@/view/pages/experience/components/content/Meeting";

export default {
  props: ["experienceId"],
  data() {
    return {
      content: null,
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
    }
  },
  components: {
    Photos,
    Setup,
    Provide,
    Highlight,
    Summary,
    FullDescription,
    Include,
    Exclude,
    Important,
    Duration,
    Price,
    Itinerary,
    Meeting
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      ApiService.get(`experience/content/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.content = data.content;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    openEditor(editor) {
      this.$emit('edit', editor);
    },
  }
}
</script>

<style scoped>
.experience-content {
  line-height: 1.8;
}
</style>