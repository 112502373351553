<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Listelenme kategorilerini düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminizin ait olduğu kategorileri seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-if="selectedCategory.mainCategory.id == null">
      <div class="d-flex flex-row align-items-center option" @click="$bvModal.show('modal-add-category')">
        <span class="option-label">
          <span class="d-flex flex-row align-items-center option-head">
            <div class="option-title pr-4 flex-fill">
              <span class="d-inline-flex align-items-center">
                <i class="flaticon2-plus-1 pr-8 text-body"></i> 
                <span class="font-size-h6 font-weight-bolder text-body">Kategori ekle</span>
              </span>
            </div>
          </span>
        </span>
      </div>
    </div>

    <div v-else>
      <div class="form-group">
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                {{ selectedCategory.mainCategory.title.tr }}
              </div>
              <div class="option-focus">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditCategoryModal"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeSelectedCategory"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 mw-80 pt-0">
              <span class="pr-3" v-for="(item, index) in selectedCategory.subCategories" :key="index">{{ item.title.tr }}</span>
            </span>
          </span>
        </div>
      </div>
    </div>


    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaving"
          @click="setCategory"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

    <!--Begin: Add category modal-->
    <b-modal id="modal-add-category" ref="modal-add-category" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/2</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addItemModal.activeStep === 1">
          <div class="form-group" v-for="(item, index) in categories" :key="index">
            <div class="option selectable mb-3" :class="{selected: addItemModal.mainCategory.id === item.id}" @click="addItemModal.mainCategory = item">
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    {{ item.title.tr }}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="checkbox-list">
              <label class="checkbox checkbox-square checkbox-dark font-size-h6 mb-6" v-for="(item, index) in addItemModal.mainCategory.subCategories" :key="index">
                <input type="checkbox" name="Checkboxes4" @change="subCategoryChecked($event, item)"/>
                <span></span>
                {{ item.title.tr }}
              </label>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.subCategories.length == 0"
            v-if="addItemModal.activeStep == addItemModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.mainCategory.id == null"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add category modal-->

    <!--Begin: Edit category modal-->
    <b-modal id="modal-edit-category" ref="modal-edit-category" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Alt kategori seçin</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div>
          <div class="checkbox-list">
              <label class="checkbox checkbox-square checkbox-dark font-size-h6 mb-6" v-for="(item, index) in editModalSubCategories" :key="index">
                <input type="checkbox" name="Checkboxes4" :checked="selectedCategory.subCategories.some( vendor => vendor['id'] === item.id )" @change="subCategoryCheckedEditModal($event, item)"/>
                <span></span>
                {{ item.title.tr }}
              </label>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editItemModal.subCategories.length == 0"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add category modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceEditorCategory',
  props: ['experienceId'],
  data() {
    return {
      selectedCategory: {
        mainCategory: {
          id: null,
          title: ''
        },
        subCategories: []
      },
      categories: [
        {
          id: 1,
          title: 'Günübirlik Turlar',
          subCategories: [
            {
              id: 1,
              title: 'Gruba Katıl'
            },
            {
              id: 2,
              title: 'Size Özel'
            },
            {
              id: 3,
              title: 'Küçük Grup'
            },
            {
              id: 4,
              title: 'Yürüyüş Turları'
            }
          ]
        },
        {
          id: 2,
          title: 'Havalimanı Transferleri',
          subCategories: [
            {
              id: 1,
              title: 'Özel Transfer'
            },
            {
              id: 2,
              title: 'Paylaşımlı Transfer'
            }
          ]
        },
        {
          id: 3,
          title: 'Paket Turlar',
          subCategories: []
        }
      ],
      addItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Ana kategori seçin'
          },
          {
            number: 2,
            title: 'Alt kategori seçin'
          }
        ],
        mainCategory: {
          id: null,
          title: '',
          subCategories: []
        },
        subCategories: []
      },
      editItemModal: {
        subCategories: []
      },
      error: {
        status: false,
        message: '',
      },
      isSaving: false
    }
  },
  computed: {
    editModalSubCategories() {
      const self = this;
      if (this.selectedCategory.mainCategory.id == null) {
        return [];
      }
      const arr = this.categories.filter((el) => {
        return el.id == self.selectedCategory.mainCategory.id;
      })[0];    
      return arr.subCategories;
    }
  },
  watch: {
    'addItemModal.mainCategory.id': function() {
      this.addItemModal.subCategories = [];
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      ApiService.get('category/getCategoriesWithSubs.req.php')
      .then(({ data }) => {
        this.categories = data.categories;  
        this.error.status = false;
        this.getSelectedCategory();
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    getSelectedCategory() {
      ApiService.get(`experience/category/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedCategory = data.category;  
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    subCategoryChecked(e, subCategory) {
      if (e.target.checked) {
        this.addItemModal.subCategories.push(subCategory);
      } else {
        let res = this.addItemModal.subCategories.filter((el) => {
          return el.id == subCategory.id;
        })[0];
        let index = this.addItemModal.subCategories.indexOf(res);
        this.addItemModal.subCategories.splice(index, 1);
      }
    },
    subCategoryCheckedEditModal(e, subCategory) {
      if (e.target.checked) {
        this.editItemModal.subCategories.push(subCategory);
      } else {
        let res = this.editItemModal.subCategories.filter((el) => {
          return el.id == subCategory.id;
        })[0];
        let index = this.editItemModal.subCategories.indexOf(res);
        this.editItemModal.subCategories.splice(index, 1);
      }
    },

    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1;
      this.addItemModal.mainCategory = { id: null, title: '' };
    },

    handleAddItemModalOk() {
      this.selectedCategory.mainCategory.id = this.addItemModal.mainCategory.id;
      this.selectedCategory.mainCategory.title = this.addItemModal.mainCategory.title;
      this.selectedCategory.subCategories = this.addItemModal.subCategories;
    },

    removeSelectedCategory() {
      this.selectedCategory.mainCategory.id = null;
      this.selectedCategory.mainCategory.title = '';
      this.selectedCategory.subCategories = [];
    },

    openEditCategoryModal() {
      this.editItemModal.subCategories = [...this.selectedCategory.subCategories];
      this.$bvModal.show('modal-edit-category');
    },

    handleEditItemModalCancel() {
      this.editItemModal.subCategories = [];
    },

    handleEditItemModalOk() {
      this.selectedCategory.subCategories = [...this.editItemModal.subCategories];
    },

    setCategory() {
      this.isSaving = true;
      let tmp = [];
      this.selectedCategory.subCategories.forEach(element => {
        tmp.push({
          "sub_category_id": element.id,
          "product_id": this.experienceId
        });
      });
      const data = {
        id: this.experienceId,
        subCategories: tmp
      }
      ApiService.post('experience/category/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  }
}
</script>

<style>

</style>