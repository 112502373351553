<template>
  <div>
    <Settings ref="settingsComponent" :experience-id="experienceId" @edit="openOverlay"/>
    <Overlay :experience-id="experienceId" :product-settings="productSettings" @close="closeOverlayModal" v-if="productSettings.selectedSection != null"/>
  </div>
</template>

<script>
import Settings from "@/view/pages/experience/components/settings/Settings";
import Overlay from "@/view/pages/experience/components/settings/Overlay";

export default {
  props: ["experienceId"],
  components: {
    Settings,
    Overlay
  },
  data() {
    return {
      productSettings: {
        selectedSection: null
      }
    }
  },
  methods: {
    closeOverlayModal() {
      this.productSettings.selectedSection = null;
      this.$refs.settingsComponent.getSettings();
      this.$emit('updated');
    },
    openOverlay(editor) {
      this.productSettings.selectedSection = editor;
    }
  }
}
</script>

<style scoped>
.experience-content {
  line-height: 1.8;
}
</style>