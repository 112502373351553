<template>
  <div>
    <div class="bg-light rounded p-10 gutter-b">
      <!--begin::Section-->
      <div class="d-flex align-items-center">
        <!--begin::Text-->
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-inline-flex flex-row align-items-center justify-content-between">
            <span class="font-weight-bold text-dark-75 font-size-h6 mb-1">{{ review.header }}</span>
            <div>
              <span class="text-muted font-weight-bold"> {{ review.date }} </span>
              <slot name="dropdown"></slot>
            </div>
          </div>
          <span v-html="rating"></span>
        </div>
        <!--end::Text-->
      </div>
      <!--end::Section-->
      <!--begin::Desc-->
      <p class="text-dark-50 m-0 pt-5 font-weight-normal font-size-lg">
        {{ review.description }}
      </p>
      <!--end::Desc-->
      <p v-if="showReviewer" class="text-muted font-weight-bold pt-2">
        {{ review.customer.name }} - {{ review.customer.country }}
      </p>
      <div>
        <slot class="pt-2" name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: Object,
    showReviewer: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rating() {
      let ratingText = '';
      for (let index = 1; index <= this.review.rate; index++) {
        ratingText += '<i class="fa fa-star mr-1 text-warning font-size-lg"></i>';
      }
      for (let index = this.review.rate + 1; index <= 5; index++) {
        ratingText += '<i class="far fa-star mr-1 text-warning font-size-lg"></i>';
      }
      return ratingText;
    }
  }
}
</script>

<style>
</style>