<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Seyahat programını düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Seyahat programınızdaki tüm önemli turistik yerleri girdiğinizden emin olun. Bu, misafirlerin deneyiminizi bulmasına ve rezervasyon yapmasına yardımcı olur.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="timeline timeline-1">
          <div class="timeline-sep bg-light-primary"></div>
          <div class="timeline-item transit-line mb-15">
            <template v-if="itinerary.start == null">
              <div class="timeline-label"></div>
              <div class="timeline-badge">
                  <i class="flaticon2-pin text-light icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bold font-size-lg">
                <button class="btn btn-transparent text-body font-weight-bold px-0 py-0" @click="$bvModal.show('modal-add-departure-point')"><u>Başlangıç noktası ekle</u></button>
              </div>
            </template>
            <template v-else>
              <div class="timeline-label"><span class="label label-dark text-white font-weight-bold label-pill label-inline">{{itinerary.start.duration.amount}} {{ durationType[itinerary.start.duration.type] }}</span></div>
              <div class="timeline-badge">
                  <i class="flaticon2-pin text-dark icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bolder font-size-h6">
                <span class="mr-3">{{ itinerary.start.title.tr }}</span>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditDepartureModal"
                >
                  <i class="flaticon2-edit icon-nm"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="itinerary.start = null"
                >
                  <i class="flaticon2-trash icon-nm"></i>
                </b-button>
              </div>
            </template>
          </div>
          <div v-for="(destination, index) in itinerary.destinations" :key="`destination_${index}`">
            <div class="timeline-item mb-50">
                <div class="timeline-label"></div>
                <div class="timeline-badge">
                    <i class="flaticon2-pin orange-pin icon-lg"></i>
                </div>
                <div class="timeline-content text-body font-weight-bolder font-size-h6">
                  <span class="mr-3">{{ destination.title.tr }}</span>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light btn-xs mr-3"
                    data-toggle="tooltip"
                    title="Düzenle"
                    @click="openEditDestinationModal(index)"
                  >
                    <i class="flaticon2-edit icon-nm"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light btn-xs"
                    data-toggle="tooltip"
                    title="Kaldır"
                    @click="removeDestination(index)"
                  >
                    <i class="flaticon2-trash icon-nm"></i>
                  </b-button>
                </div>
            </div>
            <div class="timeline-item mb-10" v-for="(stop, stopIndex) in destination.stops" :key="`stop_${index}_${stopIndex}`">
              <div class="timeline-label"></div>
              <div class="timeline-badge">
                <span class="label label-dot orange-bg label-lg"></span>
              </div>
              <div class="timeline-content text-body font-weight-normal font-size-h6">
                <span class="mr-3">{{ stop.title.tr }}</span>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditAttractionModal(index, stopIndex)"
                >
                  <i class="flaticon2-edit icon-nm"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeAttraction(index, stopIndex)"
                >
                  <i class="flaticon2-trash icon-nm"></i>
                </b-button>
              </div>
            </div>
            <div class="timeline-item mb-10">
              <div class="timeline-label"></div>
              <div class="timeline-badge">
                <span class="label label-dot label-secondary label-lg"></span>
              </div>
              <div class="timeline-content text-body font-weight-bold font-size-lg">
                <button class="btn btn-transparent text-body font-weight-bold px-0 py-0" @click="openAddAttractionModal(index)"><u>Gezilecek yer ekle</u></button>
              </div>
            </div>
          </div>
          <div class="timeline-item mb-15">
            <div class="timeline-label"></div>
            <div class="timeline-badge">
                <i class="flaticon2-pin text-light icon-lg"></i>
            </div>
            <div class="timeline-content text-body font-weight-bold font-size-lg">
              <button class="btn btn-transparent text-body font-weight-bold px-0 py-0" @click="$bvModal.show('modal-add-destination')"><u>Destinasyon ekle</u></button>
            </div>
          </div>
          <div class="timeline-item mb-20" v-if="itinerary.destinations.length > 0">
            <div class="timeline-label"></div>
            <div class="timeline-badge">
                <i class="flaticon2-time text-light icon-lg"></i>
            </div>
            <div class="timeline-content text-body font-weight-bold font-size-lg">
              <button v-if="itinerary.duration.amount == 0" class="btn btn-transparent text-body font-weight-bold px-0 py-0" @click="openTotalDurationModal"><u>Toplam gezi süresini ekle</u></button>
              <div v-else>
                <span class="label label-danger text-white font-weight-bold label-pill label-inline orange-bg">{{itinerary.duration.amount}} {{ durationType[itinerary.duration.type] }}</span>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs ml-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openTotalDurationModal"
                >
                  <i class="flaticon2-edit icon-nm"></i>
                </b-button>
              </div>
            </div>
          </div>
          <div class="timeline-item mb-15">
            <template v-if="itinerary.end == null">
              <div class="timeline-label"></div>
              <div class="timeline-badge">
                  <i class="flaticon2-pin text-light icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bold font-size-lg">
                <button class="btn btn-transparent text-body font-weight-bold px-0 py-0" @click="$bvModal.show('modal-add-end-point')"><u>Bitiş noktası ekle</u></button>
              </div>
            </template>
            <template v-else>
              <div class="timeline-label timeline-label-end"><span class="label label-dark text-white font-weight-bold label-pill label-inline">{{itinerary.end.duration.amount}} {{ durationType[itinerary.end.duration.type] }}</span></div>
              <div class="timeline-badge">
                  <i class="flaticon2-pin text-success icon-lg"></i>
              </div>
              <div class="timeline-content text-body font-weight-bolder font-size-h6">
                <span class="mr-3">{{ itinerary.end.title.tr }}</span>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditEndModal"
                >
                  <i class="flaticon2-edit icon-nm"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-xs"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="itinerary.end = null"
                >
                  <i class="flaticon2-trash icon-nm"></i>
                </b-button>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <gmap-map
          ref="map"
          :options="map.options"
          :center="map.center"
          :zoom="map.zoom"
          style="width:100%;  height: 400px;"
        >
          <template v-for="(destination, index) in itinerary.destinations">
            <gmap-marker
              :key="`marker_${index}`"
              :position="destination.location"
              :clickable="true"
              :draggable="true"
              :animation="google.maps.Animation.DROP"
              @dragend="updateDestinationCoordinates($event, index)"
              @click="openInfoWindowTemplate(destination.title.tr, destination.location)"
            />
            <gmap-marker
              v-for="(stop, stopIndex) in destination.stops"
              :key="`marker_stop_${stopIndex}`"
              :position="stop.location"
              :clickable="true"
              :draggable="true"
              @dragend="updateAttractionCoordinates($event, index, stopIndex)"
              @click="openInfoWindowTemplate(stop.title.tr, stop.location)"
              :icon="{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#FC441F',
                fillOpacity: 1,
                strokeColor: '#FC441F',
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 7
              }"
            />
          </template>

          <gmap-marker
            v-if="itinerary.start != null"
            :position="itinerary.start.location"
            :clickable="true"
            :draggable="true"
            :animation="google.maps.Animation.DROP"
            @dragend="updateDepartureCoordinates($event)"
            @click="openInfoWindowTemplate(`Başlangıç: ${itinerary.start.title.tr}`, itinerary.start.location)"
          />
          <gmap-marker
            v-if="itinerary.end != null"
            :position="itinerary.end.location"
            :clickable="true"
            :draggable="true"
            :animation="google.maps.Animation.DROP"
            @dragend="updateEndCoordinates($event)"
            @click="openInfoWindowTemplate(`Bitiş: ${itinerary.end.title.tr}`, itinerary.end.location)"
          />
          <gmap-info-window
            :options="{ maxWidth: 300, pixelOffset: {width: 0,height: -35} }"
            :position="map.infoWindow.position"
            :opened="map.infoWindow.open"
            @closeclick="map.infoWindow.open=false">
            <div v-html="map.infoWindow.template"></div>
          </gmap-info-window>
        </gmap-map>
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="itinerary.start === null || itinerary.end === null || itinerary.destinations.length === 0 || isSaving"
          @click="setItinerary"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

    <!--Begin: Add new destination modal-->
    <b-modal id="modal-add-destination" ref="modal-add-destination" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddDestinationModalCancel" @hide="handleAddDestinationModalCancel" @ok="handleAddDestinationModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addDestinationModal.activeStep }} ADIM/{{addDestinationModal.steps.length}}</label>
            <h5>{{ addDestinationModal.steps[addDestinationModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addDestinationModal.activeStep === 1">
          <div class="form-group">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Destinasyon girin"
                ref="addDestinationModalAddress"
                @place_changed="addDestinationModalPlaceChanged">
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div v-show="addDestinationModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Destinasyonun adını girin"
                ref="addDestinationModalTitleTR"
                v-model="addDestinationModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Destinasyonun adını girin"
                ref="addDestinationModalTitleEN"
                v-model="addDestinationModal.title.en"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addDestinationModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addDestinationModal.activeStep = addDestinationModal.activeStep - 1" 
            v-if="addDestinationModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addDestinationModalSaveBtnSatatus"
            v-if="addDestinationModal.activeStep == addDestinationModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addDestinationModalSaveBtnSatatus"
            @click="addDestinationModal.activeStep = addDestinationModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new destination modal-->

    <!--Begin: Edit destination modal-->
    <b-modal id="modal-edit-destination" ref="modal-edit-destination" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditDestinationModalCancel" @hide="handleEditDestinationModalCancel" @ok="handleEditDestinationModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Destinasyon adını düzenleyin</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Destinasyonun adını girin"
                ref="editDestinationModalTitleTR"
                v-model="editDestinationModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Destinasyonun adını girin"
                ref="editDestinationModalTitleEN"
                v-model="editDestinationModal.title.en"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editDestinationModalSaveBtnSatatus"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit destination modal-->

    <!--Begin: Add new attraction modal-->
    <b-modal id="modal-add-attraction" ref="modal-add-attraction" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddAttractionModalCancel" @hide="handleAddAttractionModalCancel" @ok="handleAddAttractionModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addAttractionModal.activeStep }} ADIM/{{addAttractionModal.steps.length}}</label>
            <h5>{{ addAttractionModal.steps[addAttractionModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addAttractionModal.activeStep === 1">
          <div class="form-group" v-if="addAttractionModal.isAutoCompleteAvailable">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Gezilecek yer girin"
                @place_changed="addAttractionModalPlaceChangedAutoComplete">
              </gmap-autocomplete>
            </div>
          </div>
          <label class="text-body pb-2" v-else>Haritada istediğiniz konumu bulamadınız mı? <a @click="addAttractionModal.isAutoCompleteAvailable = true" href="javascript:;" class="font-weight-bold">Bir konum arayın.</a></label>
          <div class="form-group" style="border: 2px solid var(--secondary);">  
            <gmap-map
              ref="addAttractionModalMap"
              :options="map.options"
              :center="addAttractionModal.mapCenter"
              :zoom="map.zoom"
              @click="addAttractionModalPlaceChanged"
              style="width:100%; height: 300px;"
            >
              <gmap-marker
                v-if="addAttractionModal.location != null"
                :position="addAttractionModal.location"
                :clickable="false"
                :draggable="true"
                @dragend="addAttractionModalPlaceChanged"
              />
            </gmap-map>
          </div>
        </div>
        <div v-show="addAttractionModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Gezilecek yerin adını girin"
                ref="addAttractionModalTitleTR"
                v-model="addAttractionModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Gezilecek yerin adını girin"
                ref="addAttractionModalTitleEN"
                v-model="addAttractionModal.title.en"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addAttractionModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addAttractionModal.activeStep = addAttractionModal.activeStep - 1" 
            v-if="addAttractionModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addAttractionModalSaveBtnSatatus"
            v-if="addAttractionModal.activeStep == addAttractionModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addAttractionModalSaveBtnSatatus"
            @click="addAttractionModal.activeStep = addAttractionModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new attraction modal-->

    <!--Begin: Add new attraction modal-->
    <b-modal id="modal-edit-attraction" ref="modal-edit-attraction" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditAttractionModalCancel" @hide="handleEditAttractionModalCancel" @ok="handleEditAttractionModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Gezilecek yeri düzenleyin</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Gezilecek yerin adını girin"
                ref="editAttractionModalTitleTR"
                v-model="editAttractionModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Gezilecek yerin adını girin"
                ref="editAttractionModalTitleEN"
                v-model="editAttractionModal.title.en"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editAttractionModalSaveBtnSatatus"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new attraction modal-->



    <!--Begin: Add departure point modal-->
    <b-modal id="modal-add-departure-point" ref="modal-add-departure-point" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddDepartureModalCancel" @hide="handleAddDepartureModalCancel" @ok="handleSetDepartureModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addDepartureModal.activeStep }} ADIM/{{addDepartureModal.steps.length}}</label>
            <h5>{{ addDepartureModal.steps[addDepartureModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addDepartureModal.activeStep === 1">
          <div class="form-group">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Başlangıç noktası girin"
                ref="addDepartureModalAddress"
                @place_changed="addDepartureModalPlaceChanged">
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div v-show="addDepartureModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Başlangıç yerinin adını girin"
                ref="addDepartureModalTitleTR"
                v-model="addDepartureModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Başlangıç yerinin adını girin"
                ref="addDepartureModalTitleEN"
                v-model="addDepartureModal.title.en"
              />
            </div>
          </div>
        </div>
        <div v-show="addDepartureModal.activeStep === 3">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İlk destinasyona varış süresi ne kadar?</label>
            <div class="input-group input-group-solid">
              <input type="text" class="form-control form-control-lg" v-model="addDepartureModal.duration.amount"/>
              <div class="input-group-append">
                <b-dropdown no-flip :text="durationType[addDepartureModal.duration.type]" class="btn btn-secondary">
                  <b-dropdown-item @click="addDepartureModal.duration.type = 'h'">Saat</b-dropdown-item>
                  <b-dropdown-item @click="addDepartureModal.duration.type = 'm'">Dakika</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addDepartureModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addDepartureModal.activeStep = addDepartureModal.activeStep - 1" 
            v-if="addDepartureModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addDepartureModalSaveBtnSatatus"
            v-if="addDepartureModal.activeStep == addDepartureModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addDepartureModalSaveBtnSatatus"
            @click="addDepartureModal.activeStep = addDepartureModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add departure point modal-->

    <!--Begin: Edit departure point modal-->
    <b-modal id="modal-edit-departure-point" ref="modal-edit-departure-point" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditDepartureModalCancel" @hide="handleEditDepartureModalCancel" @ok="handleEditDepartureModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editDepartureModal.activeStep }} ADIM/{{editDepartureModal.steps.length}}</label>
            <h5>{{ editDepartureModal.steps[editDepartureModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="editDepartureModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Başlangıç yerinin adını girin"
                ref="editDepartureModalTitleTR"
                v-model="editDepartureModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Başlangıç yerinin adını girin"
                ref="editDepartureModalTitleEN"
                v-model="editDepartureModal.title.en"
              />
            </div>
          </div>
        </div>
        <div v-show="editDepartureModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İlk destinasyona varış süresi ne kadar?</label>
            <div class="input-group input-group-solid">
              <input type="text" class="form-control form-control-lg" v-model="editDepartureModal.duration.amount"/>
              <div class="input-group-append">
                <b-dropdown no-flip :text="durationType[editDepartureModal.duration.type]" class="btn btn-secondary">
                  <b-dropdown-item @click="editDepartureModal.duration.type = 'h'">Saat</b-dropdown-item>
                  <b-dropdown-item @click="editDepartureModal.duration.type = 'm'">Dakika</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editDepartureModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editDepartureModal.activeStep = editDepartureModal.activeStep - 1" 
            v-if="editDepartureModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editDepartureModalSaveBtnSatatus"
            v-if="editDepartureModal.activeStep == editDepartureModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editDepartureModalSaveBtnSatatus"
            @click="editDepartureModal.activeStep = editDepartureModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add departure point modal-->


    <!--Begin: Add end point modal-->
    <b-modal id="modal-add-end-point" ref="modal-add-end-point" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddEndModalCancel" @hide="handleAddEndModalCancel" @ok="handleSetEndModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addEndModal.activeStep }} ADIM/{{addEndModal.steps.length}}</label>
            <h5>{{ addEndModal.steps[addEndModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addEndModal.activeStep === 1">
          <div class="form-group">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-location"></i>
                </span>
              </div>
              <gmap-autocomplete
                class="form-control form-control-lg"
                placeholder="Bitiş yerini girin"
                ref="addDepartureModalAddress"
                @place_changed="addEndModalPlaceChanged">
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div v-show="addEndModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Bitiş yerinin adını girin"
                ref="addEndModalTitleTR"
                v-model="addEndModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Bitiş yerinin adını girin"
                ref="addEndModalTitleEN"
                v-model="addEndModal.title.en"
              />
            </div>
          </div>
        </div>
        <div v-show="addEndModal.activeStep === 3">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Varış süresi ne kadar?</label>
            <div class="input-group input-group-solid">
              <input type="text" class="form-control form-control-lg" v-model="addEndModal.duration.amount"/>
              <div class="input-group-append">
                <b-dropdown no-flip :text="durationType[addEndModal.duration.type]" class="btn btn-secondary">
                  <b-dropdown-item @click="addEndModal.duration.type = 'h'">Saat</b-dropdown-item>
                  <b-dropdown-item @click="addEndModal.duration.type = 'm'">Dakika</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addEndModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addEndModal.activeStep = addEndModal.activeStep - 1" 
            v-if="addEndModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addEndModalSaveBtnSatatus"
            v-if="addEndModal.activeStep == addEndModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addEndModalSaveBtnSatatus"
            @click="addEndModal.activeStep = addEndModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add departure point modal-->

    <!--Begin: Edit end point modal-->
    <b-modal id="modal-edit-end-point" ref="modal-edit-end-point" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditEndModalCancel" @hide="handleEditEndModalCancel" @ok="handleEditEndModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editEndModal.activeStep }} ADIM/{{editEndModal.steps.length}}</label>
            <h5>{{ editEndModal.steps[editEndModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="editEndModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TR
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Bitiş yerinin adını girin"
                ref="editEndModalTitleTR"
                v-model="editEndModal.title.tr"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  EN
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Bitiş yerinin adını girin"
                ref="editEndModalTitleEN"
                v-model="editEndModal.title.en"
              />
            </div>
          </div>
        </div>
        <div v-show="editEndModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Varış süresi ne kadar?</label>
            <div class="input-group input-group-solid">
              <input type="text" class="form-control form-control-lg" v-model="editEndModal.duration.amount"/>
              <div class="input-group-append">
                <b-dropdown no-flip :text="durationType[editEndModal.duration.type]" class="btn btn-secondary">
                  <b-dropdown-item @click="editEndModal.duration.type = 'h'">Saat</b-dropdown-item>
                  <b-dropdown-item @click="editEndModal.duration.type = 'm'">Dakika</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editEndModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editEndModal.activeStep = editEndModal.activeStep - 1" 
            v-if="editEndModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editEndModalSaveBtnSatatus"
            v-if="editEndModal.activeStep == editEndModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editEndModalSaveBtnSatatus"
            @click="editEndModal.activeStep = editEndModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add departure point modal-->

    <!--Begin: Edit end point modal-->
    <b-modal id="modal-total-duration" ref="modal-total-duration" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleTotalDurationModalCancel" @hide="handleTotalDurationModalCancel" @ok="handleTotalDurationModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Toplam gezi süresini gir</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1">Gezi süresi</label>
          <div class="input-group input-group-solid">
            <input type="text" class="form-control form-control-lg" v-model="totalDurationModal.duration.amount"/>
            <div class="input-group-append">
              <b-dropdown no-flip :text="durationType[totalDurationModal.duration.type]" class="btn btn-secondary">
                <b-dropdown-item @click="totalDurationModal.duration.type = 'h'">Saat</b-dropdown-item>
                <b-dropdown-item @click="totalDurationModal.duration.type = 'm'">Dakika</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="totalDurationModal.duration.amount.length === 0 || totalDurationModal.duration.amount <= 0"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add departure point modal-->


  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  name: 'ExperienceEditorItinerary',
  props: ['experienceId'],
  data() {
    return {
      map: {
        zoom: 10,
        center: { lat: 41.0055066, lng: 39.731024 },
        options:{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false
        },
        infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        }
      },
      addDestinationModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Destinasyon girin'
          },
          {
            number: 2,
            title: 'Destinasyonu isimlendirin'
          }
        ],
        place: null,
        title: { tr: '', en: '' }
      },
      editDestinationModal: {
        destinationIndex: null,
        title: { tr: '', en: '' },
        duration: {
          type: 'm',
          amount: 0
        }
      },
      addAttractionModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Haritadan gezilecek bir yer seçin'
          },
          {
            number: 2,
            title: 'Gezilecek yeri isimlendirin'
          }
        ],
        destinationIndex: null,
        mapCenter: null,
        place: null,
        location: null,
        title: { tr: '', en: '' },
        isAutoCompleteAvailable: false
      },
      editAttractionModal: {
        destinationIndex: null,
        attractionIndex: null,
        title: { tr: '', en: '' },
      },
      addDepartureModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Hareket noktası girin'
          },
          {
            number: 2,
            title: 'Hareket noktasını isimlendirin'
          },
          {
            number: 3,
            title: 'Süreyi girin'
          }
        ],
        place: null,
        title: { tr: '', en: '' },
        duration: { type: 'h', amount: 0 }
      },
      editDepartureModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Hareket noktasını isimlendirin'
          },
          {
            number: 2,
            title: 'Süreyi girin'
          }
        ],
        title: { tr: '', en: '' },
        duration: { type: 'h', amount: 0 }
      },
      addEndModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Varış noktası girin'
          },
          {
            number: 2,
            title: 'Varış noktasını isimlendirin'
          },
          {
            number: 3,
            title: 'Süreyi girin'
          }
        ],
        place: null,
        title: { tr: '', en: '' },
        duration: { type: 'h', amount: 0 }
      },
      editEndModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Varış noktasını isimlendirin'
          },
          {
            number: 2,
            title: 'Süreyi girin'
          }
        ],
        title: { tr: '', en: '' },
        duration: { type: 'h', amount: 0 }
      },
      totalDurationModal: {
        duration: {
          type: 'm',
          amount: 0
        }
      },
      durationType: {
        m: 'Dakika',
        h: 'Saat'
      },
      error: {
        status: false,
        message: '',
      },
      itinerary: {
        start: null,
        destinations: [
        ],
        end: null,
      },
      isSaving: false
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi,

    addDestinationModalSaveBtnSatatus() {
      const step = this.addDestinationModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.addDestinationModal.place === null;
          break;
        case 2:
          val = this.addDestinationModal.title.tr === '' || this.addDestinationModal.title.en === '';
          break;
        case 3:
          val = this.addDestinationModal.duration.amount === '' || this.addDestinationModal.duration.amount === 0;
          break;
      }
      return val;
    },

    editDestinationModalSaveBtnSatatus() {
      return this.editDestinationModal.title.tr === '' || this.editDestinationModal.title.en === '';
    },

    addAttractionModalSaveBtnSatatus() {
      const step = this.addAttractionModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.addAttractionModal.place === null;
          break;
        case 2:
          val = this.addAttractionModal.title.tr === '' || this.addAttractionModal.title.en === '';
          break;
      }
      return val;
    },

    editAttractionModalSaveBtnSatatus() {
      return this.editAttractionModal.title.tr === '' || this.editAttractionModal.title.en === '';
    },

    addDepartureModalSaveBtnSatatus() {
      const step = this.addDepartureModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.addDepartureModal.place === null;
          break;
        case 2:
          val = this.addDepartureModal.title.tr === '' || this.addDepartureModal.title.en === '';
          break;
        case 3:
          val = this.addDepartureModal.duration.amount === '' || this.addDepartureModal.duration.amount === 0;
          break;
      }
      return val;
    },

    editDepartureModalSaveBtnSatatus() {
      const step = this.editDepartureModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.editDepartureModal.title.tr === '' || this.editDepartureModal.title.en === '';
          break;
        case 2:
          val = this.editDepartureModal.duration.amount === '' || this.editDepartureModal.duration.amount === 0;
          break;
      }
      return val;
    },

    addEndModalSaveBtnSatatus() {
      const step = this.addEndModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.addEndModal.place === null;
          break;
        case 2:
          val = this.addEndModal.title.tr === '' || this.addEndModal.title.en === '';
          break;
        case 3:
          val = this.addEndModal.duration.amount === '' || this.addEndModal.duration.amount === 0;
          break;
      }
      return val;
    },

    editEndModalSaveBtnSatatus() {
      const step = this.editEndModal.activeStep;
      let val = true;
      switch (step) {
        case 1:
          val = this.editEndModal.title.tr === '' || this.editEndModal.title.en === '';
          break;
        case 2:
          val = this.editEndModal.duration.amount === '' || this.editEndModal.duration.amount === 0;
          break;
      }
      return val;
    },
  },
  mounted() {
    this.getItinerary();
  },
  watch: {
    'itinerary.destinations.length': function(val) {
      if (val > 0) {
        this.updateBounds();
      }
    }
  },
  methods: {
    getItinerary() {
      ApiService.get(`experience/itinerary/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.itinerary = data.itinerary;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setItinerary() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        itinerary: JSON.stringify(this.itinerary)
      }

      ApiService.post('experience/itinerary/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    // Add Destination
    addDestinationModalPlaceChanged(place) {
      this.addDestinationModal.place = place;
      this.addDestinationModal.title.tr = place.name;
      this.addDestinationModal.title.en = place.name;
    },
    handleAddDestinationModalCancel() {
      this.addDestinationModal.activeStep = 1;
      this.addDestinationModal.place = null;
      this.addDestinationModal.title = { tr: '', en: '' };
    },
    handleAddDestinationModalOk() {
      let destination = Object.assign({}, this.addDestinationModal);
      const place = destination.place;
      const newDestination = {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        title: destination.title,
        stops: []
      }
      this.itinerary.destinations.push(newDestination);
    },


    // Add Attraction
    openAddAttractionModal(destinationIndex) {
      const destination = this.itinerary.destinations[destinationIndex];
      
      this.addAttractionModal.destinationIndex = destinationIndex;
      this.addAttractionModal.mapCenter = destination.location;
      this.$bvModal.show('modal-add-attraction');
    },
    addAttractionModalPlaceChanged(place) {
      this.addAttractionModal.place = place;
      const location = {
        lat: place.latLng.lat(),
        lng: place.latLng.lng()
      };
      this.addAttractionModal.location = location;
      this.addAttractionModal.mapCenter = location;
    },
    addAttractionModalPlaceChangedAutoComplete(place) {
      this.addAttractionModal.place = place;
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.addAttractionModal.location = location;
      this.addAttractionModal.mapCenter = location;
    },
    handleAddAttractionModalCancel() {
      this.addAttractionModal.activeStep = 1;
      this.addAttractionModal.destinationIndex = null;
      this.addAttractionModal.mapCenter = null;
      this.addAttractionModal.place = null;
      this.addAttractionModal.location = null;
      this.addAttractionModal.title = { tr: '', en: '' };
      this.addAttractionModal.isAutoCompleteAvailable = false;
    },
    handleAddAttractionModalOk() {
      let attraction = Object.assign({}, this.addAttractionModal);
      const newAttraction = {
        location: attraction.location,
        title: attraction.title,
      }
      const index = attraction.destinationIndex;
      this.itinerary.destinations[index].stops.push(newAttraction);
      this.updateBounds();
    },

    removeDestination(index) {
      this.itinerary.destinations.splice(index, 1);
    },

    removeAttraction(destinationIndex, attractionIndex) {
      this.itinerary.destinations[destinationIndex].stops.splice(attractionIndex, 1);
    },

    // Edit Destination
    openEditDestinationModal(destinationIndex) {
      const destination = Object.assign({}, this.itinerary.destinations[destinationIndex]);
      
      this.editDestinationModal.destinationIndex = destinationIndex;
      this.editDestinationModal.title = Object.assign({}, destination.title);
      this.$bvModal.show('modal-edit-destination');
    },
    handleEditDestinationModalCancel() {
      this.editDestinationModal.destinationIndex = null;
      this.editDestinationModal.title = { tr: '', en: '' };
    },
    handleEditDestinationModalOk() {
      const index = this.editDestinationModal.destinationIndex;
      let destination = Object.assign({}, this.itinerary.destinations[index]);
      destination.title = {
        tr: this.editDestinationModal.title.tr,
        en: this.editDestinationModal.title.en,
      } 
      
      this.itinerary.destinations[index] = destination;
      
    },

    // Edit Attraction
    openEditAttractionModal(destinationIndex, attractionIndex) {
      const destination = Object.assign({}, this.itinerary.destinations[destinationIndex]);
      const attraction = destination.stops[attractionIndex];
      
      this.editAttractionModal.destinationIndex = destinationIndex;
      this.editAttractionModal.attractionIndex = attractionIndex;
      this.editAttractionModal.title = Object.assign({}, attraction.title);
      this.$bvModal.show('modal-edit-attraction');
    },
    handleEditAttractionModalCancel() {
      this.editAttractionModal.destinationIndex = null;
      this.editAttractionModal.attractionIndex = null;
      this.editAttractionModal.title = { tr: '', en: '' };
    },
    handleEditAttractionModalOk() {
      const destinationIndex = this.editAttractionModal.destinationIndex;
      const attractionIndex = this.editAttractionModal.attractionIndex;

      let attraction = Object.assign({}, this.itinerary.destinations[destinationIndex].stops[attractionIndex]);
      attraction.title = {
        tr: this.editAttractionModal.title.tr,
        en: this.editAttractionModal.title.en,
      } 
      
      this.itinerary.destinations[destinationIndex].stops[attractionIndex] = attraction;
      
    },

    updateBounds() {
      const self = this;
      this.$refs.map.$mapPromise.then((map) => {
        var bounds = new this.google.maps.LatLngBounds();
        this.itinerary.destinations.forEach((destination) => {
          if (destination.stops.length > 0) {
            destination.stops.forEach((stop) => {
              bounds.extend(stop.location);
            })
          }
          bounds.extend(destination.location);
        });
        if (self.itinerary.start != null) {
          bounds.extend(self.itinerary.start.location);
        }
        if (self.itinerary.end != null) {
          bounds.extend(self.itinerary.end.location);
        }
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },

    updateDestinationCoordinates(location, index) {
      this.itinerary.destinations[index].location = { lat: location.latLng.lat(), lng: location.latLng.lng() };
    },

    updateAttractionCoordinates(location, destinationIndex, attractionIndex) {
      this.itinerary.destinations[destinationIndex].stops[attractionIndex].location = { lat: location.latLng.lat(), lng: location.latLng.lng() };
    },

    updateDepartureCoordinates(location) {
      this.itinerary.start.location = { lat: location.latLng.lat(), lng: location.latLng.lng() };
    },

    updateEndCoordinates(location) {
      this.itinerary.end.location = { lat: location.latLng.lat(), lng: location.latLng.lng() };
    },


    // Add Departure Point
    addDepartureModalPlaceChanged(place) {
      this.addDepartureModal.place = place;
      this.addDepartureModal.title.tr = place.name;
      this.addDepartureModal.title.en = place.name;
    },
    handleAddDepartureModalCancel() {
      this.addDepartureModal.activeStep = 1;
      this.addDepartureModal.place = null;
      this.addDepartureModal.title = { tr: '', en: '' };
      this.addDepartureModal.duration = { type: 'h', amount: 0 };
    },
    handleSetDepartureModalOk() {
      let departure = Object.assign({}, this.addDepartureModal);
      const place = departure.place;
      const newDeparture = {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        title: Object.assign({}, departure.title),
        duration: Object.assign({}, departure.duration),
      }
      this.itinerary.start = newDeparture;
      this.updateBounds();
    },

    // Edit Departure Point
    openEditDepartureModal() {
      let departure = Object.assign({}, this.itinerary.start);
      this.editDepartureModal.title = Object.assign({}, departure.title);
      this.editDepartureModal.duration = Object.assign({}, departure.duration);
      this.$bvModal.show('modal-edit-departure-point');
    },
    handleEditDepartureModalCancel() {
      this.editDepartureModal.activeStep = 1;
      this.editDepartureModal.title = { tr: '', en: '' };
      this.editDepartureModal.duration = { type: 'h', amount: 0 };
    },
    handleEditDepartureModalOk() {
      let departure = Object.assign({}, this.editDepartureModal);
      this.itinerary.start.title = Object.assign({}, departure.title);
      this.itinerary.start.duration = Object.assign({}, departure.duration);
    },


    // Add End Point
    addEndModalPlaceChanged(place) {
      this.addEndModal.place = place;
      this.addEndModal.title.tr = place.name;
      this.addEndModal.title.en = place.name;
    },
    handleAddEndModalCancel() {
      this.addEndModal.activeStep = 1;
      this.addEndModal.place = null;
      this.addEndModal.title = { tr: '', en: '' };
      this.addEndModal.duration = { type: 'h', amount: 0 };
    },
    handleSetEndModalOk() {
      let end = Object.assign({}, this.addEndModal);
      const place = end.place;
      const newEnd = {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        title: Object.assign({}, end.title),
        duration: Object.assign({}, end.duration),
      }
      this.itinerary.end = newEnd;
      this.updateBounds();
    },

    // Edit End Point
    openEditEndModal() {
      let end = Object.assign({}, this.itinerary.end);
      this.editEndModal.title = Object.assign({}, end.title);
      this.editEndModal.duration = Object.assign({}, end.duration);
      this.$bvModal.show('modal-edit-end-point');
    },
    handleEditEndModalCancel() {
      this.editEndModal.activeStep = 1;
      this.editEndModal.title = { tr: '', en: '' };
      this.editEndModal.duration = { type: 'h', amount: 0 };
    },
    handleEditEndModalOk() {
      let end = Object.assign({}, this.editEndModal);
      this.itinerary.end.title = Object.assign({}, end.title);
      this.itinerary.end.duration = Object.assign({}, end.duration);
    },

    openInfoWindowTemplate (template, location) {
      this.map.infoWindow.template = `<span class="font-weight-bold">${template}</span>`;
      this.map.infoWindow.position = location;
      this.map.infoWindow.open = true
    },

    openTotalDurationModal() {
      let duration = Object.assign({}, this.itinerary.duration);
      this.totalDurationModal.duration.type = duration.type;
      this.totalDurationModal.duration.amount = duration.amount;
      this.$bvModal.show('modal-total-duration');
    },

    handleTotalDurationModalCancel() {
      this.totalDurationModal.duration.type = 'm';
      this.totalDurationModal.duration.amount = 0;
    },

    handleTotalDurationModalOk() {
      let duration = Object.assign({}, this.totalDurationModal.duration);
      this.itinerary.duration.type = duration.type;
      this.itinerary.duration.amount = duration.amount;
    }
  }
}
</script>

<style scoped>
.option-meeting-type {
  flex: 1 1 33%;
}
.orange-pin {
  color: #FC441F;
}
.orange-bg {
  background-color: #FC441F !important;
}
.timeline-sep {
  left: 13px !important;
}
.transit-line {
  position: relative;
}
.timeline-item {
  position: relative;
  margin-left: 0;
}
.timeline-label {
  top: 40px;
  position: absolute;
  left: -10px;
}
.timeline-label.timeline-label-end {
  top: -40px;
}
</style>