<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Öne çıkanlar</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>
    <div class="highlights-list">
      <div class="d-flex align-items-baseline mb-8" v-for="(item, index) in highlights" :key="index">
        <label class="checkbox checkbox-outline checkbox-dark flex-shrink-0 m-0 mr-4">
          <input type="checkbox" checked disabled>
          <span></span>
        </label>
        <div class="d-flex flex-column flex-grow-1">
          <span class="font-weight-normal text-body font-size-h6">{{ item[lang] }}</span>
        </div>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["highlights", "lang"]
}
</script>

<style scoped>
.highlights-list {
  width: 80%;
}
</style>