<template>
  <div class="card card-fit card-custom card-stretch">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">Değerlendirmeler</h3>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-dark nav-bold nav-pills">
          <li class="nav-item">
            <button class="nav-link" :class="{'active': activeTab == 'confirmed'}" data-toggle="tab" @click="activeTab = 'confirmed'">
              <span class="nav-text">Onaylanan</span>
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" :class="{'active': activeTab == 'rejected'}" data-toggle="tab" @click="activeTab = 'rejected'">
              <span class="nav-text">Rededilen</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div class="tab-pane fade" :class="{'show active': activeTab == 'confirmed'}" role="tabpanel" aria-labelledby="confirmed">
          <PendingReviews ref="pendingReviews" :experience-id="experienceId" v-if="activeTab == 'confirmed'" @confirmed="reviewConfirmed" @rejected="reviewRejected"/>
          <ConfirmedReviews ref="confirmedReviews" :number-of-pending="numberOfPending" :experience-id="experienceId" v-if="activeTab == 'confirmed'" @revertToPending="revertToPending"/>
        </div>
        <div class="tab-pane fade" :class="{'show active': activeTab == 'rejected'}" role="tabpanel" aria-labelledby="rejected">
          <RejectedReviews :experience-id="experienceId" @revertToPending="revertToPendingFromRejected" v-if="activeTab == 'rejected'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PendingReviews from "@/view/pages/experience/components/review/PendingReviews";
import ConfirmedReviews from "@/view/pages/experience/components/review/ConfirmedReviews";
import RejectedReviews from "@/view/pages/experience/components/review/RejectedReviews";
export default {
  props: ["experienceId", "numberOfPending"],
  components: {
    PendingReviews,
    ConfirmedReviews,
    RejectedReviews
  },
  data() {
    return {
      activeTab: 'confirmed'
    }
  },
  methods: {
    reviewConfirmed() {
      this.$refs.confirmedReviews.getReviews();
      this.$emit("updated");
    },

    reviewRejected() {
      this.$emit("updated");
    },

    revertToPending() {
      this.$refs.pendingReviews.getReviews();
      this.$emit("updated");
    },

    revertToPendingFromRejected() {
      this.$emit("updated");
    }
  }
}
</script>

<style></style>